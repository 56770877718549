import Modal from 'react-modal';
import classes from './commonModalWrapper.module.css';


const customStyles = {
    content: {
        position: `absolute`,
        overflow: `scroll`,
        padding: `20px`,
        width: `100%`,
        marginLeft: `auto`,
        maxWidth: `850px`,
        maxHeight: `600px`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: `var(--white)`,
        zIndex: 10000000
    },
    overlay: {
        background: `rgba(0, 0, 0, 0.4)`,
        zIndex: 1
    }
};
const customPopupStyles = {
    content: {
        position: `absolute`,
        padding: `20px`,
        width: `100%`,
        marginLeft: `auto`,
        maxWidth: `850px`,
        maxHeight: `600px`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: `var(--white)`,
        zIndex: 10000000
    },
    overlay: {
        background: `rgba(0, 0, 0, 0.4)`,
        zIndex: 1
    }
};


function CommonModalWrapper({ ispopupStyle=false ,isOpen = false, closeModal = () => {}, className = ``, children }) {
    if(ispopupStyle == true){
        var ModelStyle = customPopupStyles
    }else{
        var ModelStyle = customStyles

    }
    return (
        <Modal
            style={ModelStyle}
            ariaHideApp={false}
            className={className + ` ` + classes.detailsModal}
            isOpen={isOpen}>

            {children}
        </Modal>
    )
}


export default CommonModalWrapper;