import React from 'react';
import style from './message.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/commonFunctions';
export default function Sucessmessage() {
    const navigate = useNavigate();
    const location = useLocation();
    const OrderresponseData = location.state?.responseData;

    // const responseData = props.location.state.responseData;
    console.log('responseData.........', OrderresponseData?.orderDetails[0]?.updatedAt)
    const updatedAtTime = OrderresponseData?.orderDetails[0]?.updatedAt;
 
    const handlePrint = () => {
        navigate('/Receiptpage', { state: { responseData: OrderresponseData, print: true } });
    };
    return (
        <div className={style.main_wrapper}>
            <div className={style.thanku_message_text}>
                <h1>Thank you for your purchase!</h1>
                <p>We sent an email to <a href='#'>{OrderresponseData?.user?.email_address}</a>  with your order confirmation and repept. If the email hasn’t arrived within 3 minutes, please check your spam folder to see fi the email was routed there.</p>
                <div className={style.time_place}>
                    <div>
                        <i class="fa-regular fa-clock"></i>

                        <h5>Time Placed: <span> {formatDate(updatedAtTime)}</span> </h5>

                    </div>
                    <div className={style.thanku_message_box_wrap}  style={{cursor:'pointer'}} onClick={handlePrint}>
                        <i class="fa-solid fa-print"></i>
                        <h6>Print </h6>
                    </div>
                </div>
                <div className={style.shopping_details_boxes}>
                    <div className={style.shopping_details_boxes_lists}>
                        <div>
                            <img src="/assets/images/Icons_Filled.png" alt="" />
                        </div>
                        <div>
                            <h3>Shipping Details</h3>
                        </div>
                        <div>
                            <ul>
                                <li>{OrderresponseData?.user?.first_name} <span>{OrderresponseData?.user?.last_name}</span></li>
                                <li>{OrderresponseData?.shippingAddress?.city?.title} </li>
                                <li>{OrderresponseData?.user?.email_address}</li>
                                <li>{OrderresponseData?.shippingAddress?.phonenumber}</li>
                                <li>{OrderresponseData?.shippingAddress?.address}</li>
                                <li>{OrderresponseData?.shippingAddress?.country} <span>{OrderresponseData?.shippingAddress?.zipCode}</span>  <span>{OrderresponseData?.shippingAddress?.city?.title} </span> </li>
                            </ul>
                        </div>
                    </div>
                    <div className={style.shopping_details_boxes_lists}>
                        <div>
                            <img src="/assets/images/Frame 575.png" alt="" />
                        </div>
                        <div>
                            <h3>Shipping Method</h3>
                        </div>
                        <div>
                            <ul>
                                <li>Preferred Method:</li>
                                <li>FedEx
                                    (2-3 weeks delivery use for I-IAE only)</li>
                            </ul>
                        </div>
                    </div>
                    <div className={style.shopping_details_boxes_lists}>
                        <div>
                            <img src="/assets/images/Frame 575 (1).png" alt="" />
                        </div>
                        <div>
                            <h3>Order Summary</h3>
                        </div>
                        <div>
                            <ul className={style.shopping_details_prices_lists}>
                                <li>Subtotal: <span>{OrderresponseData?.totalProductPrice?.subTotal} SAR</span> </li>
                                <li>Shipping Cost: <span>{OrderresponseData?.totalProductPrice?.shippingCost} SAR</span> </li>
                                <li>VAT: <span>{(OrderresponseData?.totalProductPrice?.vat.toFixed(2))} SAR</span> </li>
                                <p>Total: <span>{OrderresponseData?.totalProductPrice?.total} SAR</span> </p>
                            </ul>
                        </div>
                    </div>

                </div>
                <div>
{/* 
                    <Link to={{ pathname: "/Receiptpage", state: { responseData: OrderresponseData } }}>
                        <button className={style.continue_btn}>Continue shopping</button>
                    </Link> */}
                     <Link to="/Receiptpage" state={{ responseData: OrderresponseData }}>
                        <button className={style.continue_btn}>Continue shopping</button>
                    </Link>
                </div>
            </div>

        </div>
    )
}
