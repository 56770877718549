import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../config/config';
import { updateCartCount } from '../../utils/commonFunctions';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';

const AddToWishlistButton = ({ productId, setcartCount, cartWishCount, setCartWishCount }) => {
    const tokenExists = window.localStorage.getItem("token");
    const fetchConfig = getFetchConfig();

    const [isHeartActive, setIsHeartActive] = useState(false);

    const updateFavoriteProduct = (event) => {
        event.stopPropagation(); 
        if(tokenExists){
            fetch(`${API_BASE_URL}/product/favoriteProduct`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    productId: productId
                })
            })
            .then(handleFetchErrors)
            .then((dataupdateCartProduct) => {
                console.log(dataupdateCartProduct.data, "product added");
                if(dataupdateCartProduct.data.message === "Something went wrong"){
                    toast.success("Product removed from wishlist.");
                } else {
                    toast.success("Product added to wishlist.");
                }
                updateCartCount(setcartCount, setCartWishCount); // Update count
            })
            .catch((error)=>{
                console.log(error.message);
            });
        } else { 
            let data = window.localStorage.getItem("Product");
            if (!data) {
                const newProduct = JSON.stringify([{ _id: productId }]);
                window.localStorage.setItem("Product", newProduct);
                setIsHeartActive(true);
                toast.success("Product added to wishlist.");
            } else {
                const existingProducts = JSON.parse(data);
                const isProductInWishlist = existingProducts.filter((item) => item._id === productId);
                if (isProductInWishlist.length === 0) {
                    existingProducts.push({ _id: productId });
                    const newProduct = JSON.stringify(existingProducts);
                    window.localStorage.setItem("Product", newProduct);
                    setIsHeartActive(true);
                    toast.success("Product added to wishlist.");
                } else {
                    setIsHeartActive(true);
                    toast.success("Product is already in your wishlist.");
                }
            }
            updateCartCount(setcartCount, setCartWishCount); // Update count
        }
    };

    return (
        <span onClick={(event) => updateFavoriteProduct(event)}>
            {isHeartActive ? (
                <i className="fa-regular fa-heart" style={{ color: 'red' }}></i>
            ) : (
                <i className="fa-regular fa-heart" ></i>
            )}
        </span>
    );
};

export default AddToWishlistButton;
