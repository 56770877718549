import React from 'react';
import { Link } from 'react-router-dom'
import BottomHeroImage from '../../assets/images/CustomerReviewsbottomHero.png'
import style from './bottomHero.module.css'
import { useTranslation } from "react-i18next";

export default function BottomHero({ HeroImage = ``, Heading = ``, children }) {
    const { t } = useTranslation();
    return (
     
        <section className={style.hero_section_wrapper}>

            <div className={style.hero_image_div} style={{
                backgroundImage: `url(${BottomHeroImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '220px',
             
            }}>

                <section className={style.content_section}>
                    
                    <article className={style.contact_detail}>
                        <div className={style.svg_icon}>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_472_1948)">
                                    <path d="M16 25.3332C17.104 25.3332 18 26.2292 18 27.3332C18 28.4372 17.104 29.3332 16 29.3332C14.896 29.3332 14 28.4372 14 27.3332C14 26.2292 14.896 25.3332 16 25.3332ZM16 2.6665C20.4187 2.6665 24 6.24784 24 10.6665C24 13.5532 22.996 15.0532 20.4347 17.2305C17.8653 19.4132 17.3333 20.3958 17.3333 22.6665H14.6667C14.6667 19.3678 15.716 17.7398 18.708 15.1985C20.7307 13.4798 21.3333 12.5785 21.3333 10.6665C21.3333 7.71984 18.9467 5.33317 16 5.33317C13.0533 5.33317 10.6667 7.71984 10.6667 10.6665V11.9998H8V10.6665C8 6.24784 11.5813 2.6665 16 2.6665Z" fill="#F86B1F" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_472_1948">
                                        <rect width="32" height="32" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>

                        <aside className= { style.contact_number_email}>
                            <h1>{t('CustomerReviewLiveHelp')} </h1>
                            <p>{t('CustomerReviewphone')}<span>{t('CustomerReviewphoneNumber')}</span> - {t('CustomerReviewEmailUs')} <span>{t('CustomerReviewEmail')}</span></p>
                        </aside>
                    </article>

                    <article className={style.btn_section}>
                        <Link><p>{t('CustomerReviewFaq')}</p></Link>
                        <Link><p>{t('CustomerReviewContact')}</p></Link>
                        <Link><p>{t('CustomerReviewLiveChat')}</p></Link>
                    </article>
                </section>

            </div>

        </section>
    )
}
