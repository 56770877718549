import React from 'react';
import style from './memberlayout.module.css';
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import HeroImage from '../../components/hero-image/HeroImage';
import Image from '../../assets/images/accountPage.png'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {API_BASE_URL} from '../../config/config';
import Checkbox from '@mui/material/Checkbox';
import CancelOrder from '../../components/order/DynamicFieldComponents';
import ReturnOrder from '../../components/order/returnOrder';
import RefundOrder from '../../components/order/refundOrder';
function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function MemberLayout({ children }) {

  const { t } = useTranslation();





  return (

    <div className={style.main_wrapper1}>
      <HeroImage
        Heading={t('AccountsName')}
        HeroImage={Image}
      />
      <div className={style.main_wrapper}>
        <div className={style.order_lists}>
          <div className={style.account_order_lists}>
            <ul>
              <Link to="/orders">
                <li className={style.clickable_text}>
                  <a href="#">My Orders</a>
                </li>
              </Link>
              <li>

              </li>
              <Link to="/my-special-orders">
                <li className={style.clickable_text}>
                  <a  href="#">Special Orders</a>
                </li>
              </Link>
              <Link to="/accounts">
                <li className={style.clickable_text}>
                  <a href="#">My details</a>
                </li>
              </Link>

              <Link to="/Changepassword">
                <li className={style.clickable_text}>
                  <a href="#">Change password</a>
                </li>
              </Link>

              <li className={style.clickable_text}>
                <a href="#">Sign out</a>
              </li>
            </ul>
          </div>
          <div style={{ marginRight: 120, width: '100%' }} >
            {children}
          </div>
        </div>

      </div>

    </div>

  )
}


