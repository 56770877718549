
import React from 'react';
import Pagination from '../../components/paginations/Pagination';
import BottomHeroImage from '../../assets/images/CustomerReviewsbottomHero.png'
import BottomHero from '../../components/bottom-hero/BottomHero'
import style from "./customersReviews.module.css"
import { useTranslation } from "react-i18next";

export default function CustomersReviews() {
    const { t } = useTranslation();
    return (
        <>
            <div className={style.main_wrapper}>
                <h1 className={style.heading}>{t('CustomerReviewHeading')}</h1>
                <section className={style.CustomersReviews_section}>
                    <div className={style.Reviews_section_box}>
                        <div>
                            <h2>{t('CustomerReviewWrite')}</h2>
                        </div>
                        <div>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star-half-stroke"></i>
                            <i class="fa-regular fa-star"></i>
                        </div>
                    </div>
                </section>
                <section>
                    <div className={style.CustomersReviews_wrapper} >
                        <div className={style.reviews_content}>
                            <h2>{t('CustomerReviewHeadingName')}</h2>
                            <p>{t('CustomerReviewDate')} {t('CustomerReviewTime')}</p>
                            <div>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <i class="fa-regular fa-star"></i>
                                <span>{t('CustomerRating')}</span>
                            </div>
                        </div>
                        <div className={style.reviews_text} >
                            <p>{t('CustomerReviewText')}</p>
                        </div>
                    </div>
                    <div className={style.CustomersReviews_wrapper} >
                        <div className={style.reviews_content}>
                        <h2>{t('CustomerReviewHeadingName')}</h2>
                            <p>{t('CustomerReviewDate')} {t('CustomerReviewTime')}</p>
                            <div>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <i class="fa-regular fa-star"></i>
                                <span>{t('CustomerRating')}</span>
                            </div>
                        </div>
                        <div className={style.reviews_text} >
                        <p>{t('CustomerReviewText')}</p>
                        </div>
                    </div>
                    <div className={style.CustomersReviews_wrapper} >
                        <div className={style.reviews_content}>
                        <h2>{t('CustomerReviewHeadingName')}</h2>
                            <p>{t('CustomerReviewDate')} {t('CustomerReviewTime')}</p>
                            <div>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <i class="fa-regular fa-star"></i>
                                <span>{t('CustomerRating')}</span>
                            </div>
                        </div>
                        <div className={style.reviews_text} >
                        <p>{t('CustomerReviewText')}</p>
                        </div>
                    </div>
                    <div className={style.CustomersReviews_wrapper} >
                        <div className={style.reviews_content}>
                        <h2>{t('CustomerReviewHeadingName')}</h2>
                            <p>{t('CustomerReviewDate')} {t('CustomerReviewTime')}</p>
                            <div>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <i class="fa-regular fa-star"></i>
                                <span>{t('CustomerRating')}</span>
                            </div>
                        </div>
                        <div className={style.reviews_text} >
                        <p>{t('CustomerReviewText')}</p>
                        </div>
                    </div>
                    <div className={style.CustomersReviews_wrapper} >
                        <div className={style.reviews_content}>
                        <h2>{t('CustomerReviewHeadingName')}</h2>
                            <p>{t('CustomerReviewDate')} {t('CustomerReviewTime')}</p>
                            <div>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <i class="fa-regular fa-star"></i>
                                <span>{t('CustomerRating')}</span>
                            </div>
                        </div>
                        <div className={style.reviews_text} >
                        <p>{t('CustomerReviewText')}</p>
                        </div>
                    </div>
                    <div className={style.CustomersReviews_wrapper} >
                        <div className={style.reviews_content}>
                        <h2>{t('CustomerReviewHeadingName')}</h2>
                            <p>{t('CustomerReviewDate')} {t('CustomerReviewTime')}</p>
                            <div>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <i class="fa-regular fa-star"></i>
                                <span>{t('CustomerRating')}</span>
                            </div>
                        </div>
                        <div className={style.reviews_text} >
                        <p>{t('CustomerReviewText')}</p>
                        </div>
                    </div>
                    <div className={style.CustomersReviews_wrapper} >
                        <div className={style.reviews_content}>
                        <h2>{t('CustomerReviewHeadingName')}</h2>
                            <p>{t('CustomerReviewDate')} {t('CustomerReviewTime')}</p>
                            <div>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <i class="fa-regular fa-star"></i>
                                <span>{t('CustomerRating')}</span>
                            </div>
                        </div>
                        <div className={style.reviews_text} >
                        <p>{t('CustomerReviewText')}</p>
                        </div>
                    </div>
                </section>
                <Pagination classNameForLoadMoreText={style.load_more_text} viewAllBtnText='Load more' svgIcon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_540_2232)">
                        <path d="M9.75048 12.129L13.7735 8.106L14.834 9.1665L9.00048 15L3.16698 9.1665L4.22748 8.106L8.25048 12.129V3H9.75048V12.129Z" fill="#212F63" />
                    </g>
                    <defs>
                        <clipPath id="clip0_540_2232">
                            <rect width="18" height="18" fill="white" transform="matrix(0 1 -1 0 18 0)" />
                        </clipPath>
                    </defs>
                </svg>
                } />
            </div>
            <section className={style.bottom_hero_section}>
                <BottomHero HeroImage={BottomHeroImage} />
            </section>
        </>

    );

}
