import React, { useState } from 'react';

import { toast } from 'react-toastify';

import HeroImage from '../../components/hero-image/HeroImage';
import Image from '../../assets/images/accountPage.png';
import style from './changepassword.module.css';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../../config/config';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleChangePassword = async () => {
        if (newPassword !== confirmNewPassword) {
            toast.error('New password and confirm password do not match');
            return;
        }

        const userProfileInfo = {
            email_address: email,
            current_password: currentPassword,
            new_password: newPassword,
        };

        try {
            const response = await fetch(`${API_BASE_URL}/account/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userProfileInfo),
            });

            const result = await response.json();
            if (result.data.message === 'Password updated successfully') {
                toast.success('Password changed successfully');
                navigate('/accounts');
            } else {
                toast.error(result.data.message);
            }
        } catch (error) {
            console.error('Error changing password', error);
            toast.error('Failed to change password');
        }
    };

    return (
        <main className={style.main_wrapper}>
       
            <HeroImage Heading={t('Change Password')} HeroImage={Image} />
            <div className={style.form_wrapper}>
                <div className={style.form_group}>
                    <label>{t('Email')}</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className={style.form_group}>
                    <label>{t('Current Password')}</label>
                    <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                </div>
                <div className={style.form_group}>
                    <label>{t('New Password')}</label>
                    <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </div>
                <div className={style.form_group}>
                    <label>{t('Confirm New Password')}</label>
                    <input type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                </div>
                <button className={style.button_style} onClick={handleChangePassword}>{t('Change Password')}</button>
            </div>
        </main>
    );
}
