import React from 'react';
import './css.css'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cardImage from "../../../assets/images/productPage/productCard/cardImage.png";
import style from "./categories.modules.css";
import noImageFound from '../../../assets/images/no-img-found.png';
import {IMG_URL} from '../../../config/config'
export default function CategoriesCard({ activeStyle = null, defaultStyle = null, handleCategory, subCategoryId = null, categoryId = null, title = "", imageURL = "" }) {

    const { t } = useTranslation();
    // const imageBaseURL = `https://admin-anycarpart.go-demo.com`;
    const imageBaseURL = `http://localhost:3003/`;
    return (

        <div onClick={handleCategory} className="container-fluid bg-trasparent my-4 p-3" style={{ position: "relative" }}  >

            <div className="col hp">


                <div className=" shadow-sm">
                    {/* <img src={imageURL ?`${imageBaseURL}/${imageURL}`: `/assets/images/break.png`} className="card-img-top" alt="product.productTitle" /> */}
                    {imageURL && imageURL.length > 0 ? (

                        <img src={`${IMG_URL}/${imageURL[0]}`} className="card-img-top" alt="product.productTitle"
                        onError={(e) => {
                            e.target.src = "../../../assets/images/noimg-png-output.png";
                        }}
                        />
                    ) : (
                        <img src="../../../assets/images/no-img-found.png" className="card-img-top" alt="No Image Found" />
                    )}

                    <div>


                        <h5 style={{ textAlign: "center" }} className={style.card_heading}>
                            {title}
                            {/* <span> {t('Header34')}</span> */}
                        </h5>

                    </div>
                </div>
            </div>

        </div>

    )
}
