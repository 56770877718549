import React, { Component } from 'react'
import loading from './loader.gif'
import style from './loader.module.css';
export class Loader extends Component {
  render() {
    return (
     
        <img src={loading} alt="loading"  style={{margin:"50% 85%"}}  />
   
    )
  }
}

export default Loader