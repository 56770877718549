import React from 'react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import style from '../../pages/product-details/productDetails.module.css'; 
import ProductCard from '../product-card/ProductCard';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const CustomSwiper = ({ productRelatedDetails, cartWishCount }) => {
  const nextButtonStyle = {
    color: '#000',
    width: '30px',
    height: '30px',
    marginTop: '-30px',
    right: '-35px', // Move the next button further left
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '20px',
    fontWeight: 'bold',
    zIndex: 10 // Ensure button is above Swiper slides
  };

  const prevButtonStyle = {
    color: '#000',
    width: '30px',
    height: '30px',
    marginTop: '-30px',
    left: '-35px', 
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '20px',
    fontWeight: 'bold',
    zIndex: 10 // Ensure button is above Swiper slides
  };

  return (
    productRelatedDetails?.length > 0 ? (
      <section className={style.product_details_wrapper}>
        <div className={style.heading2}>
          <h2 style={{marginBottom:'5px'}}>Related Products</h2>
        </div>
        <div className={style.product_details_boxes_images} style={{ position: 'relative' }}>
          <Swiper
            modules={[Navigation, Pagination, A11y, Autoplay]}
            spaceBetween={20}
            slidesPerView={4} // Display 4 slides per view
            navigation={{ 
              nextEl: '.swiper-button-next', 
              prevEl: '.swiper-button-prev' 
            }}
            // pagination={{ clickable: true }}
            autoplay={{ delay: 2000 }} 
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 60,
              }
            }}
          >
            {productRelatedDetails.map((product, index) => (
              <SwiperSlide key={product._id}>
                <ProductCard
                  cartWishCount={cartWishCount}
                  wishDetail={product.isFavorite}
                  productId={product._id}
                  subcategoryId={product.subcategoryId}
                  productThumbnailShort={product?.thumbnail ? product?.thumbnail[0] : ""}
                  productTitle={product.title}
                  productSku={product.sku}
                  productCurrency={product.currency}
                  productPrice={product.price}
                  vinNumber={product.vin_number}
                  product={product}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-next" style={nextButtonStyle}></div>
          <div className="swiper-button-prev" style={prevButtonStyle}></div>
        </div>
      </section>
    ) : null
  );
};

export default CustomSwiper;
