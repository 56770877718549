import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
import style  from './payment.module.css'
import { ThreeDots } from  'react-loader-spinner'
import { API_BASE_URL } from '../../config/config';
const MERCHANT_IDENTIFIER = '791974ce';
const ACCESS_CODE = '1b5LjmffuJ0wv9jSawiC';
const SHA_TYPE = 'SHA-256';
const SHA_REQUEST_PHRASE = '14Ea02SiAUBF1CKlTTe8Vu{#';
const SHA_RESPONSE_PHRASE = '15qs3EJ14p6p.SSXE03Rsb_';


function generateMerchantReference() {
  // Generate a random alphanumeric string
  const length = 10;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let merchantReference = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    merchantReference += characters.charAt(randomIndex);
  }
  return merchantReference;
}

const PaymentForm = () => {
  // const appURL =`http://localhost:3003/api/v1`
  // const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
  const { id } = useParams();


  const [orderAvailable, setOrderAvailable] = useState();

  useEffect(() => {
    if (id) {
      fetch(`${API_BASE_URL}/productOrder/oderdetail/${id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
          // Parse the response body as JSON
        })
        .then((data) => {
          console.log(data.data.orders, "dherj");
          setOrderAvailable(data.data.orders)// Access the parsed JSON data
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });

    }
  }, [id]);

  useEffect(() => {
    if (orderAvailable) {
      let shaString = '';
      const redirectUrl = 'https://sbcheckout.payfort.com/FortAPI/paymentPage';
      let requestParams = {
        command: 'PURCHASE',
        access_code: ACCESS_CODE,
        merchant_identifier: MERCHANT_IDENTIFIER,
        merchant_reference: orderAvailable._id,
        amount: orderAvailable.total * 100,
        currency: 'SAR',
        language: 'en',
        customer_email: 'test@payfort.com',
        order_description: 'iPhone 6-S',  
        return_url: 'https://admin-anycarpart.go-demo.com/api/v1/productOrder/payment/success',
      };

      // Sort the object properties by key
      const sortedRequestData = Object.keys(requestParams)
        .sort()
        .reduce((acc, key) => {
          acc[key] = requestParams[key];
          return acc;
        }, {});

      // // Construct the shaString
      for (const key in sortedRequestData) {
        shaString += `${key}=${sortedRequestData[key]}`;
      }

      shaString = SHA_REQUEST_PHRASE + shaString + SHA_REQUEST_PHRASE;

      const signature = CryptoJS.SHA256(shaString).toString(CryptoJS.enc.Hex);

      requestParams = { ...requestParams, signature: signature };

      const form = document.createElement('form');
      form.action = redirectUrl;
      form.method = 'post';

      for (const key in requestParams) {
        if (requestParams.hasOwnProperty(key)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = requestParams[key];
          form.appendChild(input);
        }
      }

      document.body.appendChild(form);
      form.submit();
    }
  }, [orderAvailable]);

  return (
    <div style={{display:"flex",justifyContent:"center"}}>
        <ThreeDots 
                    height="60" 
                    width="100" 
                    radius="9"
                    color="#4fa94d" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
          />
    </div>
  );
};

export default PaymentForm;