const commonRegex = require(`./commonRegex`);

exports.isValidIndianMobileNumber = (mobileNumber = null) => {
    return mobileNumber && String(mobileNumber) && String(mobileNumber).match(commonRegex.indianMobileNumberRegex);
};

// /**
//  * @function
//  * @description Validates whether provided value is a String and is truthy
//  * @param {string} str Value to validate as a truthy String
//  * @returns {boolean}
//  */
exports.isTruthyString = (str = ``) => {
    return Boolean(str && typeof str === `string` && str.trim());
};

exports.isValidEmailAddress = (emailAddress = null) => {
    return emailAddress && String(emailAddress) && String(emailAddress).match(commonRegex.emailAddressRegex);
};

// exports.isValidPassword = (password=null) => {
//     return (password
//      && String(password)
//      && String(password).match(commonRegex.passwordRegex));
// }

// exports.isFetchRequest = (req) => {
//     return Boolean(
//         (req.query[`fetch-req`] && Boolean(req.query[`fetch-req`]))
//         || (req.headers[`fetch-req`] && Boolean(req.headers[`fetch-req`]))
//         || (req.body[`fetch-req`] && Boolean(req.body[`fetch-req`]))
//         || req.xhr
//     );
// }

// exports.JSONResponseStatus = (statusText=null) => {
//     if(typeof statusText !== `string`) return new Error(`Invalid data-type of status-text`);

//     const lowerCasedStatusText = statusText.trim().toLowerCase();

//     const isSuccess = Boolean(lowerCasedStatusText === `success`),
//         isFailure = Boolean(lowerCasedStatusText === `fail`),
//         isError = Boolean(lowerCasedStatusText === `error`);

//     return { isSuccess, isFailure, isError }
// }

// exports.isAcceptableImageFormat = (format=null) => {
//     if(format && (typeof format === `string`) && format.trim()){
//         const acceptableFormatsRegex = /(jpe?g|png)$/ig;

//         return Boolean(format.match(acceptableFormatsRegex));
//     }
// }

// exports.validateAndCreateAddressObj = (obj=null) => {
//     const missingMandatoryData = [];

//     if(obj && (typeof obj === `object`) && Object.keys(obj).length){
//         if(!obj.line_1) missingMandatoryData.push(`address`);
//         if(!obj.city) missingMandatoryData.push(`city`);
//         if(!obj.state) missingMandatoryData.push(`state`);
//         if(!obj.zipcode) missingMandatoryData.push(`pincode`);

//         if(missingMandatoryData.length){
//             return {
//                 error: { message: `You missed filling your ${ missingMandatoryData.join(`, `) }` }
//             }
//         }

//         if(!(obj.zipcode && obj.zipcode.match(commonRegex.indianAreaPincodeRegex))){
//             return {
//                 error: { message: `Invalid Indian Pincode` }
//             }
//         }

//         return { success: true }
//     }
//     else return {
//         error: { message: `Please provide all data` }
//     }
// }

// exports.isValidDate = (date=null) => {
//     const dateObj = new Date(date);

//     if(dateObj.getTime() - dateObj.getTime() === 0) return true;
//     else return false;
// }

exports.india = {
    /**
     * @method
     * @description Evaluates whether value is a valid Indian GST Identification Number
     * @param {string} gstin Value to validate as a valid Indian GST Identification Number
     * @returns {boolean}
     */
    gstin: (gstin = null) => {
        const stringifiedGSTIN = String(gstin);

        return Boolean(gstin && stringifiedGSTIN && stringifiedGSTIN.match(commonRegex.govtID.india.gstin));
    },

    isValidPincode: (pincode = null) => {
        const stringifiedPincode = String(pincode);

        return Boolean(pincode && stringifiedPincode && stringifiedPincode.match(commonRegex.pincode.india));
    },

    /**
     * @method
     * @description Evaluates whether value is a valid Indian PAN ID
     * @param {string} pan Value to validate as a valid Indian PAN ID
     * @returns {boolean}
     */
    pan: (pan = null) => {
        const stringifiedPAN = String(pan);

        return Boolean(pan && stringifiedPAN && stringifiedPAN.match(commonRegex.govtID.india.pan));
    },

    /**
     * @method
     * @description Evaluates whether value is a valid Indian Aadhar Number
     * @param {number|string} aadhar Value to validate as a valid Indian Aadhar Number
     * @returns {boolean}
     */
    aadhar: (aadhar = null) => {
        const stringifiedAadhar = String(aadhar);

        return Boolean(aadhar && stringifiedAadhar && stringifiedAadhar.match(commonRegex.govtID.india.aadhar));
    }
};
