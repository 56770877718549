import React from 'react';
import style from './checkout.module.css';
import { Link } from 'react-router-dom';
export default function Checkout() {
    return (
        <div className={style.main_wrapper}>
            <div className={style.checkout_cart}>
                <h1>Checkout</h1>
                <p>3 item(s) in Cart</p>
            </div>
            <div className={style.checkout_cart_coupancode}>
                <div className={style.checkout_cart_heading}>
                    <img src="/assets/Group 569 (1).png" alt="" />
                    <span>Have a coupon? <h4>Click here to enter your code</h4></span>
                </div>
                <div>
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
            <div className={style.checkout_cart_customer_product}>
                <div className={style.checkout_cart_customer_products}>
                    <div className={style.checkout_cart_customer}>
                        <span>1 <h3>Customer</h3></span>
                        <div className={style.checkout_form_box}>
                            <input type="text" placeholder="Email address" />
                            <Link to="/Checkoutpickup">
                                 <button>Continue</button>
                            </Link>
                           
                        </div>
                        <div className={style.checkout_subscribe}>
                            <img src="/assets/On.png" alt="" />
                            <h3>Subscribe to our newsletter.</h3>
                        </div>
                        <p>Already have an account?<h5> Sign in now</h5></p>
                    </div>
                    <div className={style.checkout_cart_gray_box}>
                        <span>2 <p>Pickup from store or indicate shipping option</p></span>
                    </div>
                    <div className={style.checkout_cart_gray_box}>
                        <span>3<p>Payment</p></span>
                    </div>
                </div>
                <div className={style.checkout_order_summery}>
                    <h2>Order Summary</h2>
                    <div className={style.checkout_items_products}>
                        <div className={style.checkout_items_oil_filters}>
                            <div>
                                <img src="/assets/Rectangle 114 (1).png" alt="" />
                            </div>
                            <div >
                                <div>
                                    <h5>Oil Filter</h5>
                                </div>
                                <div>
                                    <h4>LR164821</h4>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div>
                                <h5>98.00 SAR</h5>
                            </div>
                            <div>
                                <h4>LR164821</h4>
                            </div>

                        </div>
                    </div>
                    <div className={style.checkout_items_products}>
                        <div className={style.checkout_items_oil_filters}>
                            <div>
                                <img src="/assets/Rectangle 114 (1).png" alt="" />
                            </div>
                            <div >
                                <div>
                                    <h5>Oil Filter</h5>
                                </div>
                                <div>
                                    <h4>LR164821</h4>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div>
                                <h5>98.00 SAR</h5>
                            </div>
                            <div>
                                <h4>LR164821</h4>
                            </div>

                        </div>
                    </div>
                    <div className={style.checkout_items_products}>
                        <div className={style.checkout_items_oil_filters}>
                            <div>
                                <img src="/assets/Rectangle 114 (1).png" alt="" />
                            </div>
                            <div >
                                <div>
                                    <h5>Oil Filter</h5>
                                </div>
                                <div>
                                    <h4>LR164821</h4>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div>
                                <h5>98.00 SAR</h5>
                            </div>
                            <div>
                                <h4>LR164821</h4>
                            </div>

                        </div>
                    </div>
                    <div className={style.order_summary_section}>
                        <div className={style.order_summary}>
                            <div className={style.order_summary_lists}>
                                <p>Sub Total:</p>
                                <p>Estimated Shipping Cost:</p>
                                <p>Coupon card:</p>
                                <p>VAT:</p>
                                <h2>Total:</h2>
                            </div>

                            <div className={style.order_summary_lists1}>
                                <p>1,392 SAR</p>
                                <p>0 SAR</p>
                                <p>-</p>
                                <p>-</p>
                                <h2>1,392 SAR</h2>
                            </div>

                        </div>

                    </div>
                </div>



                
            </div>
        </div>
    )
}
