import { API_BASE_URL } from "../config/config";
import getFetchConfig from "./getFetchConfig";
import handleFetchErrors from "./handleFetchErrors";
import standardFetchHandlers from "./standardFetchHandlers";
const fetchConfig = getFetchConfig();

export const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  // updateCartUtils.js
export const updateCartCount = (setcartCount, setCartWishCount, setQuantity) => {
  fetch(`${API_BASE_URL}/account/cartCount`, {
      ...fetchConfig,
      method: `GET`,
  })
  .then(handleFetchErrors)
  .then((dataCount) => {
    // console.log("Received data:", dataCount.data);
    // console.log("____uuuuuuuuuuuuuuuuu", dataCount.data.cartCount, dataCount.data.favoriteProductCount);
    //   console.log(dataCount.data, "Cart and wish count updated")
    setCartWishCount(dataCount.data.favoriteProductCount);
      setcartCount(dataCount.data.cartCount);
      setQuantity(dataCount.data.totalQuantity)
      console.log('wqqqqqqqqq', dataCount.data.cartCount)
  })
  .catch(standardFetchHandlers.error);

};
export const formatDate=(dateString) => {
  if (!dateString) return 'Invalid Date';
 
     const date = new Date(dateString);
 
     if (isNaN(date.getTime())) {
         return 'Invalid Date';
     }
 
     const day = String(date.getDate()).padStart(2, '0');
     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
     const year = date.getFullYear();
     const hours = date.getHours();
     const minutes = String(date.getMinutes()).padStart(2, '0');
     const ampm = hours >= 12 ? 'PM' : 'AM';
     const formattedHours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0)
 
     return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
 }