// const API_BASE_URL = 'https://admin-anycarpart.go-demo.com/api/v1';
// const API_BASE_URL = 'http://localhost:3003/api/v1';
// const IMG_URL = 'http://localhost:3003/';
const API_BASE_URL = 'https://js-projects-dashboard-anycarpart.0yxzv7.easypanel.host/api/v1';
const IMG_URL = 'https://js-projects-dashboard-anycarpart.0yxzv7.easypanel.host/';

export { API_BASE_URL, IMG_URL };

// export default API_BASE_URL;

