import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_BASE_URL } from '../../config/config';
import { toast } from 'react-toastify';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [verificationError, setVerificationError] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      const token = searchParams.get('token');

      if (!token) {
        setVerificationError('Verification token is missing');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/account/verify-email?token=${token}`, {
          method: 'GET',
        });

        const result = await response.json();

        if (response.ok) {
          toast.success('Email verified successfully!');
          navigate('/accounts'); 
        } else {
          setVerificationError(result.message);
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationError('Something went wrong. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, [navigate, searchParams]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center',minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      {isLoading ? (
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h2>Verifying your email...</h2>
          <p>This process might take a few moments.</p>
          <div style={{ display: 'inline-block', width: '64px', height: '64px', position: 'relative', borderRadius: '50%', border: '8px solid #f3f3f3', animation: 'spin 1s linear infinite' }}>
            <div style={{ position: 'absolute', borderRadius: '50%', width: '80%', height: '80%', backgroundColor: 'transparent', border: '8px solid #f3f3f3' }}></div>
            <div style={{ position: 'absolute', borderRadius: '50%', width: '80%', height: '80%', backgroundColor: 'transparent', border: '8px solid #f3f3f3', animation: 'spin2 1s linear infinite' }}></div>
            <div style={{ position: 'absolute', borderRadius: '50%', width: '80%', height: '80%', backgroundColor: 'transparent', border: '8px solid #f3f3f3', animation: 'spin3 1s linear infinite' }}></div>
          </div>
        </div>
        
      ) : verificationError ? (
        <div style={{ textAlign: 'center' }}>
          <h1>Email verification failed</h1>
          <p style={{ fontSize: '1.2rem', color: 'red' }}>{verificationError}</p>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <h1>Email verification completed!</h1>
          <p style={{ fontSize: '1.2rem', color: 'green', fontWeight: 'bold' }}>
            Your email address (<strong>{searchParams.get('email')}</strong>) has been successfully verified. You can now sign in to your account using this email address.
          </p>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;