import React from 'react'
import style from "./giftcard.module.css";
import HeroImage from '../../components/hero-image/HeroImage';
import ProductHeroImage from '../../assets/images/NewsDetailHero.png';
import { useTranslation } from "react-i18next";
export default function Giftcard() {
    const { t } = useTranslation();
    return (
        <>
            <HeroImage HeroImage={ProductHeroImage} Heading={t('GiftCartHeading')}/>
      
        <div className={style.main_wrapper}>
            <div className={style.gift_cards_list}>
                <div className={style.gift_cards_text}>
                    <h2>{t('GiftCartAboutUs')}</h2>
                    <h3>{t('GiftCartAboutUsHeading')}</h3>
                    <p>{t('GiftCartAboutUsText')}</p>
                </div>
                <div className={style.gift_cards_img}>
                <img src="/assets/card.png" alt="" />
                </div>
            </div>
            <div className={style.benefits_section}>
                    <h2>{t('GiftCartSeller')}</h2>
                    <h3>{t('GiftCartSellerHeading')}</h3>
                    <p>{t('GiftCartSellerText')}</p>
                </div>
                <div className={style.Gift_cards_items}>
                        <div className={style.Gift_cards_items1}>
                                <h2>{t('GiftCartBasic')}</h2>
                                <h3>{t('GiftCartBasicNumber')}<span>{t('GiftCartBasicSAR')}</span></h3>
                                <ul>
                                    <li>Nibh elementum ipsum auctor.</li>
                                    <li>Senectus tristique justo.</li>
                                    <li>Et turpis mi a amet amet.</li>
                                    <li>Cursus tincidunt nullam.</li>
                                    <li>Quis lorem suscipit auctor.</li>
                                </ul>
                                <button>{t('GiftCard')}</button>
                        </div>  
                        <div className={style.Gift_cards_items2}>
                                <h2>{t('GiftCartBasic')}</h2>
                                <h3>{t('GiftCartBasicNumber')}<span>{t('GiftCartBasicSAR')}</span></h3>
                                <ul>
                                    <li>Nibh elementum ipsum auctor.</li>
                                    <li>Senectus tristique justo.</li>
                                    <li>Et turpis mi a amet amet.</li>
                                    <li>Cursus tincidunt nullam.</li>
                                    <li>Quis lorem suscipit auctor.</li>
                                </ul>
                                <button>{t('GiftCard')}</button>
                        </div>  
                        <div className={style.Gift_cards_items1}>
                                <h2>{t('GiftCartBasic')}</h2>
                                <h3>{t('GiftCartBasicNumber')}<span>{t('GiftCartBasicSAR')}</span></h3>
                                <ul>
                                    <li>Nibh elementum ipsum auctor.</li>
                                    <li>Senectus tristique justo.</li>
                                    <li>Et turpis mi a amet amet.</li>
                                    <li>Cursus tincidunt nullam.</li>
                                    <li>Quis lorem suscipit auctor.</li>
                                </ul>
                                <button>{t('GiftCard')}</button>
                        </div>  
                </div>
        </div>
        </>
    )
}
