import React, { useState, useEffect } from 'react';
import style from './productCard.module.css';
import { Link , useNavigate } from 'react-router-dom';
import ProductHeroImage from '../../assets/images/home/productCard.png';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Heart } from '@mui/icons-material';

import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import fetchAllCartProduct from '../../pages/product-details/ProductDetails';

import CommonModalWrapper from '../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../Icons/CloseIcon';
import SearchProduct from '../search-product/SearchProduct';

import RegisterCard from '../auth/register-card/RegisterCard';
import LoginCard from '../auth/login-card/LoginCard';
import ForgotPasswordCard from '../auth/forgot-password-card/ForgotPasswordCard';
import { HeartBroken } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import {API_BASE_URL, IMG_URL} from '../../config/config';
import { updateCartCount } from '../../utils/commonFunctions';

export default function ProductCard({ setcartCount, setCartWishCount, cartCount ,cartWishCount,productId=null,wishDetail=null,subcategoryId=null,productTitle,productPrice,productCurrency,productThumbnailShort,productSku, vinNumber,product}) {
    const base_url = process.env.REACT_APP_BASE_URL;
	// const imageURL = `https://admin-anycarpart.go-demo.com`;
	const imageURL = `http://localhost:3003/`;

    // const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
    const [modalIsOpen, updateModalVisibility] = useState(false);
    const [modalIsOpenForLogin, updateModalVisibilityLogin] = useState(false);
    const [modalIsOpenForForgotPassWord, updateModalVisibilityForForgotPassWord] = useState(false);
    const [modalIsOpenForSearch, updateModalVisibilityForSearch] = useState(false);
    const tokenExits =window.localStorage.getItem('token')
    let [num, setNum] = useState(1);
     const fetchConfig = getFetchConfig();
    const navigate = useNavigate();
    const [isHeartActive, setIsHeartActive] = useState(false);

    const goToproductPosts = () => {
        if (localStorage.getItem('token')) {
            let newUrl =`/product-details?subcategoryId=${subcategoryId}&productId=${productId}`
            // navigate(`/product-details?subcategoryId=${subcategoryId}&productId=${productId}`)
            window.open(newUrl, '_blank')
            // console.log('qqqqqqqqqqqqqqq')
        } 
        else{
    
            let newUrl =`/product-details?subcategoryId=${subcategoryId}&productId=${productId}`

            // navigate(`/product-details?subcategoryId=${subcategoryId}&productId=${productId}`)
            window.open(newUrl, '_blank')
            // console.log('nnnnnnnnnnnnnnn')

        }
      };


  
    const toggleModalForRegisterForSearch = () => {
      updateModalVisibilityForSearch(true)
    }

    // const HeartBroken =(e)=>{
    //         e.stopPropagation();  
    //     if (!localStorage.getItem('token')) {
    //         toast.error(`Please Login to Add Product To Wishlist`);
            
    //     }
    // }

    let isToastShown = false;
    const toggleModalForRegister = (toggleValue = false) => {
      updateModalVisibilityLogin(false)
      updateModalVisibility(toggleValue)
    }
  
    const toggleModalForLogin = (toggleValue = false) => {
      updateModalVisibility(false)
      updateModalVisibilityLogin(toggleValue)
    }
  
    const toggleModalForForgotPassWord = (toggleValue = false) => {
      updateModalVisibilityLogin(false)
      updateModalVisibilityForForgotPassWord(toggleValue)
    }
  
    const [isOpen, setIsOpen] = useState(false);
  
    const [tokenExist, updateToken] = useState(false);
  
    const openPopModel = () =>{
      setIsOpen(true);
    }

   

    const updateFavoriteProduct = (event, productDetails) => {
        event.stopPropagation();
        
        // Check if user is logged in
        if (!tokenExits) {
            // User is not logged in, handle accordingly (e.g., show login modal)
            toggleModalForLogin(true);
            return; // Exit function early
        }
    
        // Proceed with existing functionality for logged-in user
        fetch(`${API_BASE_URL}/product/favoriteProduct`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                productId: productDetails._id
            })
        })
        .then(handleFetchErrors)
        .then((dataupdateCartProduct) => {
            if (dataupdateCartProduct.data.message === "Something went wrong") {
                toast.success("Product removed from wishlist.");
            } else {
                toast.success("Product added to wishlist.");
            }
            updateCartCount(setcartCount, setCartWishCount);
        })
        .catch((error) => {
            console.log(error.message);
        });
    
        // Optionally, you may handle local storage logic here for non-logged in users
        if (!tokenExits) {
            let data = window.localStorage.getItem("Product");
            if (!data) {
                const newProduct = JSON.stringify([productDetails]);
                window.localStorage.setItem("Product", newProduct);
                setIsHeartActive(true);
                toast.success("Product added to wishlist.");
            } else {
                const existingProducts = JSON.parse(data);
                const isProductInWishlist = existingProducts.filter((item) => item._id === productDetails._id);
                if (isProductInWishlist.length === 0) {
                    existingProducts.push(productDetails);
                    const newProduct = JSON.stringify(existingProducts);
                    window.localStorage.setItem("Product", newProduct);
                    setIsHeartActive(true)

                    toast.success("Product added to wishlist.");
                } else {
                    setIsHeartActive(true)
                    toast.success("Product is already in your wishlist.");
                }
            }
            updateCartCount(setcartCount, setCartWishCount);
       
        }
    };
    
    // /cart/updateCart
  

    const updateCartProduct = (event, productDetails) => {
        event.stopPropagation();
        
        // Check if user is logged in
        if (!tokenExits) {
            // User is not logged in, handle accordingly (e.g., show login modal)
            toggleModalForLogin(true);
            return; // Exit function early
        }
    
        // Proceed with existing functionality for logged-in user
        fetch(`${API_BASE_URL}/cart/updateCart`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                id: productDetails._id,
                quantity: 1
            })
        })
        .then(handleFetchErrors)
        .then((dataupdateCartProduct) => {
            toast.success("Product added to cart.");
            updateCartCount(setcartCount, setCartWishCount);
        })
        .catch((error) => {
            console.log(error.message);
        });
    
        // Optionally, handle local storage logic for non-logged in users here
        if (!tokenExits) {
            let cart = window.localStorage.getItem("ProductCart");
            
            if (!cart) {
                // If the cart doesn't exist, create a new cart with the product
                productDetails.quantity = 1;
                productDetails.totalPrice = productDetails.price;
                const newProduct = JSON.stringify([productDetails]);
                window.localStorage.setItem("ProductCart", newProduct);
                toast.success("Product added to Cart.");
            } else {
                const existingProducts = JSON.parse(cart);
                let updated = false;
    
                for (let i = 0; i < existingProducts.length; i++) {
                    if (existingProducts[i]._id === productDetails._id) {
                        // If the product ID matches, update quantity and total price
                        existingProducts[i].quantity += 1;
                        existingProducts[i].price += productDetails.price; // Add price to existing
                        updated = true;
                        break;
                    }
                }
    
                if (!updated) {
                    // If the product is not in the cart, add it
                    productDetails.quantity = 1;
                    productDetails.price = productDetails.price;
                    existingProducts.push(productDetails);
                }
    
                const newProduct = JSON.stringify(existingProducts);
                window.localStorage.setItem("ProductCart", newProduct);
                toast.success("Product added to Cart or quantity updated in Cart.");
            }
            updateCartCount(setcartCount, setCartWishCount);
        }
    };
    
    


    const updateCart=()=>{
        if (!localStorage.getItem('token')) {
            toast.error(`Please Login to Add Product To Cart`);
        }
    }
    useEffect(() => {

        if (localStorage.getItem(`token`)) {
          updateToken(true)
        }
      }, [localStorage.getItem(`token`), tokenExist])
      const HeartButton = () => {
        const [isLiked, setIsLiked] = useState(false);
      
        const handleClick = () => {
          setIsLiked(!isLiked);
        };
    }      

    useEffect(() => {
        const storedHeartActive = localStorage.getItem('isHeartActive');
        setIsHeartActive(storedHeartActive ? JSON.parse(storedHeartActive) : false);
    }, []);
    return (

        <>
            <CommonModalWrapper isOpen={modalIsOpen} >
                <CloseIcon onClick={() => toggleModalForRegister(false)} />

                <RegisterCard toggleModalForLogin={toggleModalForLogin} updateModalVisibility={updateModalVisibility} />
            </CommonModalWrapper>
            <CommonModalWrapper isOpen={modalIsOpenForLogin} className={style.login_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForLogin(false)} />

                <LoginCard toggleModalForForgotPassWord={toggleModalForForgotPassWord} toggleModalForRegister={toggleModalForRegister} updateModalVisibilityLogin={updateModalVisibilityLogin} />
            </CommonModalWrapper>

            {/* forgetPassword */}
            <CommonModalWrapper isOpen={modalIsOpenForForgotPassWord} className={style.login_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForForgotPassWord(false)} />

                <ForgotPasswordCard />
            </CommonModalWrapper>

            <CommonModalWrapper isOpen={modalIsOpenForSearch} className={style.search_modal_opener} >
                <CloseIcon onClick={() => toggleModalForRegisterForSearch(false)} />

                <SearchProduct />
            </CommonModalWrapper>

            <div onClick={goToproductPosts} className={style.main_wrapper}>
                {/* {
                    tokenExist ?
                    <> */}
                        <section className={style.image_container}
                    style={{
                        // backgroundImage: `url(${imageURL}/${productThumbnailShort})`,
                        backgroundImage: productThumbnailShort?`url("${IMG_URL}/${productThumbnailShort}")`:`url("../../assets/images/noimg-png-output.png")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        minHeight: '350px',
                        maxWidth: '350px',
                        
                    }} >
                    <div className={style.cart_like_view_btn_wrapper}>
                    <span onClick={(event) => updateFavoriteProduct(event, product)}>
                      {tokenExits?( <i className="fa fa-heart" style={product?product.isFavorite==1?{color:"red"}:{color:"grey"}:null}></i>):(<i className="fa fa-heart" style={{color:"grey"}}></i>)} 
                    </span>

                        <span onClick={(e) => {
                            updateCartProduct(e,product) }} >
                      
                            <svg width="17" height="17" viewBox="0 0 17 17  " fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.00061 3.81062L0.568359 1.37912L1.62961 0.317871L4.06111 2.75012H15.4926C15.6095 2.75011 15.7248 2.77743 15.8293 2.8299C15.9337 2.88236 16.0245 2.95852 16.0943 3.05229C16.1641 3.14607 16.211 3.25486 16.2313 3.36999C16.2516 3.48511 16.2447 3.60339 16.2111 3.71537L14.4111 9.71537C14.3648 9.86994 14.2699 10.0054 14.1404 10.1018C14.011 10.1981 13.854 10.2501 13.6926 10.2501H4.50061V11.7501H12.7506V13.2501H3.75061C3.5517 13.2501 3.36093 13.1711 3.22028 13.0305C3.07963 12.8898 3.00061 12.699 3.00061 12.5001V3.81062ZM4.50061 4.25012V8.75012H13.1346L14.4846 4.25012H4.50061ZM4.12561 16.2501C3.82724 16.2501 3.54109 16.1316 3.33011 15.9206C3.11914 15.7096 3.00061 15.4235 3.00061 15.1251C3.00061 14.8268 3.11914 14.5406 3.33011 14.3296C3.54109 14.1187 3.82724 14.0001 4.12561 14.0001C4.42398 14.0001 4.71013 14.1187 4.92111 14.3296C5.13208 14.5406 5.25061 14.8268 5.25061 15.1251C5.25061 15.4235 5.13208 15.7096 4.92111 15.9206C4.71013 16.1316 4.42398 16.2501 4.12561 16.2501ZM13.1256 16.2501C12.8272 16.2501 12.5411 16.1316 12.3301 15.9206C12.1191 15.7096 12.0006 15.4235 12.0006 15.1251C12.0006 14.8268 12.1191 14.5406 12.3301 14.3296C12.5411 14.1187 12.8272 14.0001 13.1256 14.0001C13.424 14.0001 13.7101 14.1187 13.9211 14.3296C14.1321 14.5406 14.2506 14.8268 14.2506 15.1251C14.2506 15.4235 14.1321 15.7096 13.9211 15.9206C13.7101 16.1316 13.424 16.2501 13.1256 16.2501Z" fill="#212F63" />
                            </svg>
                       
                        </span>
                    </div>
                </section>   
                <section className={style.product_detail_section}>
                    <h1 className={style.product_name}>{productTitle}</h1>
                        {/* <p className={style.company_name}>{t('productCardName')}</p>
                        <p className={style.product_category}>{t('productCardSubHeading')}</p> */}
                        <p className={style.product_sku}>{`SKU ${productSku}`}</p>
                        <p className={`${style.product_prices} ${style.arabic_product_prices}`}>{productPrice}<span>{productCurrency}</span> 
                       </p>
                       {/* <p className={style.product_sku}> {`Vin Number ${vinNumber}`}</p> */}

                </section>
            </div>
            {/* </Link> */}
        </>
        
    )
}
