import React from 'react';
import { Link } from 'react-router-dom';
import NewsImages from '../../assets/images/home/latestNewsImage.png'
import style from './latestNewsCard.module.css';

import { useTranslation } from "react-i18next";
export default function LatestNewsCard({imageSrc}) {
    const { t } = useTranslation();
    return (
        <Link to="/news-details">
            <div className={style.main_wrapper}>
                {/* <section className={style.image_container}
                   <img style={{
                        
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '270px',
                        maxWidth: '100%'
                    }} />
                </section> */}
                <div className={style.image_container}>
                    <img src={imageSrc} alt="Latest News"style={{
                        
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '270px',
                        maxWidth: '100%'
                    }} />
                </div>

                {/* <img src={NewsImages} alt="" /> */}

                <section className={style.news_card_content}>

                    <aside className={style.date_section}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.75 2.25H14.75C14.9489 2.25 15.1397 2.32902 15.2803 2.46967C15.421 2.61032 15.5 2.80109 15.5 3V15C15.5 15.1989 15.421 15.3897 15.2803 15.5303C15.1397 15.671 14.9489 15.75 14.75 15.75H1.25C1.05109 15.75 0.860322 15.671 0.71967 15.5303C0.579018 15.3897 0.5 15.1989 0.5 15V3C0.5 2.80109 0.579018 2.61032 0.71967 2.46967C0.860322 2.32902 1.05109 2.25 1.25 2.25H4.25V0.75H5.75V2.25H10.25V0.75H11.75V2.25ZM2 6.75V14.25H14V6.75H2ZM3.5 8.25H5V9.75H3.5V8.25ZM7.25 8.25H8.75V9.75H7.25V8.25ZM11 8.25H12.5V9.75H11V8.25Z" fill="#6F758B" />
                        </svg>

                        <p className={style.date_text}>{t('latestNewsDate')}</p>
                    </aside>

                    <h1 className={style.content_heading}>{t('latestNewCardHeading')}</h1>

                    <p className={style.content}>{t('latestNewCarddecriptions')}</p>

                    <span className={style.read_more_action_btn}>{t('readmore')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#212F63" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
                    </span>

                </section>

            </div>
        </Link>


    )

}
