import React from 'react'
import style from './buyWholesale.module.css';
import ServiceCard from '../../components/services-card/ServiceCard';
import Input from '../../components/input/Input';
export default function BuyWholesale() {
    return (
        <main className={style.main_wrapper}>
            <article className={style.description_section}>
                <h1>Buy Wholesale</h1>
                <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
                    Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
            </article>

            <section className={style.contact_form_section_wrapper}>
                <p className={style.sub_heading}>Buy Wholesale</p>
                <h1>Send Us a Message</h1>
                <p className={style.contact_us_desc}>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p>
                <form id={style.contact_us_from}>
                    <Input type='text' placeholder='Full Name' />
                    <Input type='text' placeholder='Contact Number' />
                    <Input type='text' placeholder='Email Address' />
                    <Input type='select' placeholder='Buyer Category' />
                    <Input id='span-2' type='textarea' placeholder='Enter your message' />
                    <button className='btn1' id='span-2'>Send a Message</button>
                </form>
            </section>
            <section className={style.car_campany_section_wrapper}>
                <p>Buy Wholesale</p>
                <h1>Source from thousands of brands, all in one place</h1>
                <div className={style.car_logo_section}>
                    <div>
                        <img src="/assets/lexus.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/nisan.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/mitach.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/hundai.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/bmw.png" alt="" />
                    </div>

                    <div>
                        <img src="/assets/audi.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/ww.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/toyata.png" alt="" />
                    </div>

                    <div>
                        <img src="/assets/kia.png" alt="" />
                    </div>
                    <div>
                        <img src="/assets/renult.png" alt="" />
                    </div>
                </div>

            </section>
            <section className={style.retailers_details_section_wrapper}>
                <p>Buy Wholesale</p>
                <h1>Why over 50,000 retailers trust us for their wholesale needs</h1>
                <h4>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </h4>

                <div className={style.retailers_card_section}>
                    <article className={style.retailers_card}>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M52.7059 1.64697V18.1176H47.7647V8.23521H31.2941V18.1176H24.7059V8.23521H8.23529V18.1176H3.29412V1.64697H0V54.3529H8.23529H24.7059H31.2941H47.7647H56V1.64697H52.7059ZM34.5882 11.5293H37.8824V14.8234H41.1765V11.5293H44.4706V18.1176H34.5882V11.5293ZM11.5294 11.5293H14.8235V14.8234H18.1176V11.5293H21.4118V18.1176H11.5294V11.5293ZM8.23529 21.4117H24.7059H31.2941H47.7647H52.7059V34.5882H49.4118V24.7058H39.5294V34.5882H36.2353V24.7058H19.7647V34.5882H16.4706V24.7058H6.58824V34.5882H3.29412V21.4117H8.23529ZM46.1176 34.5882H42.8235V27.9999H46.1176V34.5882ZM29.6471 31.294V27.9999H32.9412V34.5882H23.0588V27.9999H26.3529V31.294H29.6471ZM13.1765 34.5882H9.88235V27.9999H13.1765V34.5882ZM11.5294 51.0587V44.4705H14.8235V47.7646H18.1176V44.4705H21.4118V51.0587H11.5294ZM34.5882 51.0587V44.4705H37.8824V47.7646H41.1765V44.4705H44.4706V51.0587H34.5882ZM47.7647 51.0587V41.1764H31.2941V51.0587H24.7059V41.1764H8.23529V51.0587H3.29412V37.8823H6.58824H16.4706H19.7647H36.2353H39.5294H49.4118H52.7059V51.0587H47.7647Z" fill="#F86B1F" />
                        </svg>
                        <h3>Garage Customers</h3>
                        <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p>

                    </article>

                    <article className={style.retailers_card}>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M52.7059 1.64697V18.1176H47.7647V8.23521H31.2941V18.1176H24.7059V8.23521H8.23529V18.1176H3.29412V1.64697H0V54.3529H8.23529H24.7059H31.2941H47.7647H56V1.64697H52.7059ZM34.5882 11.5293H37.8824V14.8234H41.1765V11.5293H44.4706V18.1176H34.5882V11.5293ZM11.5294 11.5293H14.8235V14.8234H18.1176V11.5293H21.4118V18.1176H11.5294V11.5293ZM8.23529 21.4117H24.7059H31.2941H47.7647H52.7059V34.5882H49.4118V24.7058H39.5294V34.5882H36.2353V24.7058H19.7647V34.5882H16.4706V24.7058H6.58824V34.5882H3.29412V21.4117H8.23529ZM46.1176 34.5882H42.8235V27.9999H46.1176V34.5882ZM29.6471 31.294V27.9999H32.9412V34.5882H23.0588V27.9999H26.3529V31.294H29.6471ZM13.1765 34.5882H9.88235V27.9999H13.1765V34.5882ZM11.5294 51.0587V44.4705H14.8235V47.7646H18.1176V44.4705H21.4118V51.0587H11.5294ZM34.5882 51.0587V44.4705H37.8824V47.7646H41.1765V44.4705H44.4706V51.0587H34.5882ZM47.7647 51.0587V41.1764H31.2941V51.0587H24.7059V41.1764H8.23529V51.0587H3.29412V37.8823H6.58824H16.4706H19.7647H36.2353H39.5294H49.4118H52.7059V51.0587H47.7647Z" fill="#F86B1F" />
                        </svg>
                        <h3>Garage Customers</h3>
                        <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p>

                    </article>

                    <article className={style.retailers_card}>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M52.7059 1.64697V18.1176H47.7647V8.23521H31.2941V18.1176H24.7059V8.23521H8.23529V18.1176H3.29412V1.64697H0V54.3529H8.23529H24.7059H31.2941H47.7647H56V1.64697H52.7059ZM34.5882 11.5293H37.8824V14.8234H41.1765V11.5293H44.4706V18.1176H34.5882V11.5293ZM11.5294 11.5293H14.8235V14.8234H18.1176V11.5293H21.4118V18.1176H11.5294V11.5293ZM8.23529 21.4117H24.7059H31.2941H47.7647H52.7059V34.5882H49.4118V24.7058H39.5294V34.5882H36.2353V24.7058H19.7647V34.5882H16.4706V24.7058H6.58824V34.5882H3.29412V21.4117H8.23529ZM46.1176 34.5882H42.8235V27.9999H46.1176V34.5882ZM29.6471 31.294V27.9999H32.9412V34.5882H23.0588V27.9999H26.3529V31.294H29.6471ZM13.1765 34.5882H9.88235V27.9999H13.1765V34.5882ZM11.5294 51.0587V44.4705H14.8235V47.7646H18.1176V44.4705H21.4118V51.0587H11.5294ZM34.5882 51.0587V44.4705H37.8824V47.7646H41.1765V44.4705H44.4706V51.0587H34.5882ZM47.7647 51.0587V41.1764H31.2941V51.0587H24.7059V41.1764H8.23529V51.0587H3.29412V37.8823H6.58824H16.4706H19.7647H36.2353H39.5294H49.4118H52.7059V51.0587H47.7647Z" fill="#F86B1F" />
                        </svg>
                        <h3>Garage Customers</h3>
                        <p>Rutrum augue at cursus pulvinar in vestibulum nibh. Non eu, dignissim risus pharetra dictum. </p>

                    </article>

                </div>
            </section>
            <section className={style.service_section_wrapper}>
                <p>Buy Wholesale</p>
                <h1>Related category for your business</h1>
                <main className={style.service_card_wrapper}>
                    <ServiceCard />
                    <ServiceCard />
                    <ServiceCard />
                    <ServiceCard />
                    <ServiceCard />
                    <ServiceCard />
                </main>
            </section>
            <section className={style.top_categgories_section_wrapper}>
                <p className={style.top_categgories_section_sub_heading}>Buy Wholesale</p>
                <h1 className={style.top_categgories_section_heading}>Check out other product categories</h1>
                <section className={style.top_categgories_card_section} >
                    <div id='hhover'>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
                            <path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
                            <path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
                            <path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
                            <path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
                            <path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
                            <path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
                        </svg>
                        <p>Air Conditioning</p>
                    </div>
                    <div>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
                            <path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
                            <path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
                            <path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
                            <path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
                            <path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
                            <path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
                            <path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
                            <path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
                            <path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
                            <path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
                        </svg>
                        <p>Batteries & Electricals</p>
                    </div>
                    <div>
                        <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
                            <path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
                            <path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
                        </svg>
                        <p>Body & Chassis Parts</p>
                    </div>
                    <div>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
                            <path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
                            <path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
                            <path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
                            <path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
                            <path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
                            <path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
                        </svg>
                        <p>Brake Parts</p>
                    </div>
                    <div>
                        <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
                            <path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
                            <path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
                        </svg>
                        <p>Drivetrain System</p>
                    </div>
                    <div>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
                            <path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
                            <path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
                            <path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
                            <path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
                            <path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
                            <path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
                            <path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
                            <path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
                            <path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
                            <path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
                        </svg>
                        <p>Engine Parts</p>
                    </div>
                    <div id='hhover'>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
                            <path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
                            <path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
                            <path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
                            <path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
                            <path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
                            <path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
                        </svg>

                        <p>Air Conditioning</p>
                    </div>
                    <div>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
                            <path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
                            <path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
                            <path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
                            <path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
                            <path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
                            <path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
                            <path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
                            <path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
                            <path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
                            <path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
                        </svg>
                        <p>Batteries & Electricals</p>
                    </div>
                    <div>
                        <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
                            <path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
                            <path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
                        </svg>
                        <p>Body & Chassis Parts</p>
                    </div>
                    <div>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 4.42969V29.8776H56V4.42969H0ZM52.7188 26.5964H48.3073V20.0339H7.69267V26.5964H3.28125V7.71094H52.7188V26.5964ZM10.9739 26.5964V23.3151H45.026V26.5964H10.9739Z" fill="#212F63" />
                            <path d="M31.0254 13.4712H34.3066V16.7524H31.0254V13.4712Z" fill="#212F63" />
                            <path d="M38.0254 13.4712H41.3066V16.7524H38.0254V13.4712Z" fill="#212F63" />
                            <path d="M45.0254 13.4712H48.3066V16.7524H45.0254V13.4712Z" fill="#212F63" />
                            <path d="M26.3594 34.0703H29.6406V51.5703H26.3594V34.0703Z" fill="#212F63" />
                            <path d="M19.3589 39.0331C19.3589 42.8924 16.2192 46.0321 12.3599 46.0321C10.0172 46.0321 8.11133 44.1261 8.11133 41.7834C8.11133 40.4745 9.1762 39.4097 10.4852 39.4097C11.0894 39.4097 11.581 39.9013 11.581 40.5055H14.8623C14.8623 38.0919 12.8987 36.1284 10.4852 36.1284C7.36703 36.1284 4.83008 38.6653 4.83008 41.7834C4.83008 45.9354 8.20791 49.3134 12.3599 49.3134C18.0285 49.3134 22.6402 44.7017 22.6402 39.0331V34.0703H19.3589V39.0331Z" fill="#212F63" />
                            <path d="M45.5143 36.1284C43.1008 36.1284 41.1371 38.0919 41.1371 40.5055H44.4184C44.4184 39.9013 44.91 39.4097 45.5143 39.4097C46.8232 39.4097 47.8881 40.4745 47.8881 41.7834C47.8881 44.1261 45.9822 46.0321 43.6395 46.0321C39.7803 46.0321 36.6406 42.8924 36.6406 39.0331V34.0703H33.3594V39.0331C33.3594 44.7017 37.9711 49.3134 43.6395 49.3134C47.7915 49.3134 51.1693 45.9354 51.1693 41.7834C51.1695 38.6653 48.6326 36.1284 45.5143 36.1284Z" fill="#212F63" />
                        </svg>
                        <p>Brake Parts</p>
                    </div>
                    <div>
                        <svg width="56" height="42" viewBox="0 0 56 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0137 21.9258H20.307V25.207H11.0137V21.9258Z" fill="#212F63" />
                            <path d="M42.1994 18.9194H38.9182V21.9254H35.9121V25.2066H38.9182V28.2127H42.1994V25.2066H45.2055V21.9254H42.1994V18.9194Z" fill="#212F63" />
                            <path d="M56 7.36146H48.8817V0.911621H32.2348V7.36146H23.984V0.911621H7.33709V7.36146H0V10.6427H1.50161V41.0887H54.717V10.6427H56V7.36146ZM35.516 4.19287H45.6005V7.36146H35.516V4.19287ZM10.6183 4.19287H20.7028V7.36146H10.6183V4.19287ZM51.4359 37.8075H4.78286V10.6427H51.4358V37.8075H51.4359Z" fill="#212F63" />
                        </svg>


                        <p>Drivetrain System</p>
                    </div>
                    <div>

                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1171 17.3789C19.2222 17.3789 13.6133 22.9878 13.6133 29.8827C13.6133 36.7776 19.2222 42.3865 26.1171 42.3865C33.012 42.3865 38.6208 36.7776 38.6208 29.8827C38.6208 22.9878 33.012 17.3789 26.1171 17.3789ZM26.1171 39.1052C21.0324 39.1052 16.8945 34.9673 16.8945 29.8827C16.8945 24.7976 21.0324 20.6602 26.1171 20.6602C31.2021 20.6602 35.3396 24.7976 35.3396 29.8827C35.3396 34.9673 31.2021 39.1052 26.1171 39.1052Z" fill="#212F63" />
                            <path d="M26.1177 23.8149C22.7719 23.8149 20.0508 26.5365 20.0508 29.8823C20.0508 33.228 22.7719 35.9492 26.1177 35.9492C29.4634 35.9492 32.185 33.228 32.185 29.8823C32.185 26.5365 29.4634 23.8149 26.1177 23.8149ZM26.1177 32.6679C24.5809 32.6679 23.332 31.4191 23.332 29.8823C23.332 28.3455 24.5809 27.0962 26.1177 27.0962C27.6545 27.0962 28.9037 28.3455 28.9037 29.8823C28.9037 31.4191 27.6545 32.6679 26.1177 32.6679Z" fill="#212F63" />
                            <path d="M28.0355 47.5236C28.0355 48.4802 27.2605 49.2552 26.3039 49.2552C25.3473 49.2552 24.5723 48.4802 24.5723 47.5236C24.5723 46.567 25.3473 45.792 26.3039 45.792C27.2605 45.792 28.0355 46.567 28.0355 47.5236Z" fill="#212F63" />
                            <path d="M36.6063 44.1945C36.1277 43.3665 35.0686 43.0832 34.2406 43.5618C33.4126 44.0403 33.1289 45.0998 33.6078 45.9278C34.0864 46.7559 35.1455 47.0391 35.9735 46.5606C36.8015 46.0821 37.0848 45.0225 36.6063 44.1945Z" fill="#212F63" />
                            <path d="M42.3602 37.0305C41.5318 36.5529 40.4726 36.8374 39.9954 37.6663C39.5177 38.4947 39.8023 39.5538 40.6311 40.0311C41.4595 40.5087 42.5187 40.2242 42.9959 39.3953C43.4736 38.5669 43.189 37.5082 42.3602 37.0305Z" fill="#212F63" />
                            <path d="M18.5865 13.8532C18.1059 13.0265 17.0459 12.7458 16.2191 13.2269C15.392 13.7075 15.1117 14.7675 15.5924 15.5942C16.073 16.4209 17.133 16.7016 17.9597 16.221C18.7865 15.7403 19.0672 14.6803 18.5865 13.8532Z" fill="#212F63" />
                            <path d="M11.5793 19.7739C10.7491 19.2983 9.69129 19.5859 9.21576 20.4156C8.74024 21.2457 9.02777 22.3036 9.85748 22.7791C10.6872 23.2546 11.7455 22.9675 12.221 22.1374C12.6965 21.3077 12.409 20.2494 11.5793 19.7739Z" fill="#212F63" />
                            <path d="M10.2098 30.13C10.2059 29.1734 9.42793 28.401 8.47132 28.4048C7.51515 28.4082 6.74269 29.1867 6.74611 30.1433C6.74995 31.0994 7.52797 31.8719 8.48457 31.8681C9.44117 31.8646 10.2132 31.0862 10.2098 30.13Z" fill="#212F63" />
                            <path d="M12.4683 38.056C11.9868 37.2297 10.9264 36.9507 10.1001 37.4322C9.27382 37.9141 8.9944 38.9746 9.47633 39.8004C9.95826 40.6267 11.0183 40.9061 11.8446 40.4242C12.6709 39.9427 12.9503 38.8823 12.4683 38.056Z" fill="#212F63" />
                            <path d="M18.3878 43.7923C17.5576 43.318 16.4998 43.6064 16.0251 44.437C15.5508 45.2675 15.8397 46.325 16.6698 46.7996C17.5004 47.2739 18.5582 46.9851 19.0325 46.1549C19.5071 45.3244 19.2183 44.2665 18.3878 43.7923Z" fill="#212F63" />
                            <path d="M47.2479 8.75214C41.6039 3.10864 34.0998 0 26.1176 0H24.4769V3.81488C18.1178 4.20667 12.194 6.86969 7.64984 11.4143C2.71686 16.3473 0 22.9064 0 29.8824C0 36.8581 2.71686 43.4176 7.64984 48.3502C12.5824 53.2831 19.1419 56 26.1176 56C33.0936 56 39.6527 53.2831 44.5857 48.3502C49.0239 43.9119 51.6677 38.1578 52.1531 31.9683H55.9684L55.9957 30.2554C55.9979 30.1315 56 30.0072 56 29.8824C56 21.9002 52.8914 14.3961 47.2479 8.75214V8.75214ZM45.5688 28.687C44.9826 19.0398 37.346 11.2656 27.7582 10.4628V3.33167C41.2703 4.15753 52.0886 15.1168 52.6927 28.687H45.5688ZM26.1176 52.7188C13.5253 52.7188 3.28125 42.4747 3.28125 29.8824C3.28125 17.8414 12.6469 7.94849 24.4769 7.1051V13.6761H26.1176C35.0534 13.6761 42.3239 20.9466 42.3239 29.8824C42.3239 30.0179 42.3226 30.1525 42.3196 30.287L42.2777 31.9683H48.859C47.8033 43.5859 38.0065 52.7188 26.1176 52.7188V52.7188Z" fill="#212F63" />
                        </svg>

                        <p>Engine Parts</p>
                    </div>
                </section>
            </section>
            <div className={style.wholesale_wrapper_image}>
                <img src="/assets/images/Picwholsale.png" alt="" />
                <h2>Want to buy in bulk? We are here to help</h2>
                <button>Contact Us</button>
            </div>
        </main>
    )
}
