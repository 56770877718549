import React from 'react'
import MemberLayout from '../../layouts/memberLayout/memberLayout'
import style from './myaccount.module.css';
import { useTranslation } from "react-i18next";

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL, IMG_URL } from '../../config/config';
import DynamicFieldComponents from '../../components/order/DynamicFieldComponents';
import { event } from 'jquery';
import { Padding } from '@mui/icons-material';
import CancelPopup from '../../components/order/successfulPopup';



export default function CancelOrderPage() {


    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const imageURL = `https://admin-anycarpart.go-demo.com`;
    const fetchConfig = getFetchConfig();
    const { t } = useTranslation();

    const [productOrder, setProductOrder] = useState([]);
    const [address, setAddress] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const location = useLocation();
    const message = new URLSearchParams(location.search).get('message');
    const [selectedItems, setSelectedItems] = useState([]);
    const [isCancelPopupVisible, setCancelPopupVisible] = useState(false);

    const { orderId } = useParams();

    const getProductOrder = (orderId) => {
        fetch(`${API_BASE_URL}/productOrder/single-order/${orderId}`, {
            ...fetchConfig,
            method: 'GET',
        })
            .then(handleFetchErrors)
            .then((dataplace) => {
                // Make sure to check if the data is available before accessing its properties
                if (dataplace.data && dataplace.data.order) {
                    const { order } = dataplace.data;
                    setProductOrder(order);
                    setAddress(order.shippingAddress);
                    setOrderDetails(order.orderDetail);
                }
            })
            .catch(standardFetchHandlers.error);
    };


    const navigatorHandler = (id) => {
        navigate(`/paymentForm/${id}`);
    };

    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (orderIndex) => {
        const isAlreadyRequested = selectedItems.includes(orderIndex);
        const isAlreadyCancelled = productOrder.orderDetail[orderIndex].isCancelled;

        if (!isAlreadyCancelled) {
            if (isAlreadyRequested) {
                setSelectedItems(prevSelectedItems =>
                    prevSelectedItems.filter(itemIndex => itemIndex !== orderIndex)
                );
            } else {
                setSelectedItems(prevSelectedItems => [...prevSelectedItems, orderIndex]);
            }
        } else {
            toast.error("This product has already been cancelled.");
        }
    }


    const handleSelectAllChange = () => {
        setSelectAll((prevSelectAll) => !prevSelectAll);
        setSelectedItems((prevSelectedItems) =>
            selectAll ? [] : productOrder.orderDetail.map((_, index) => index)
        );

    };


    const [fields, setFields] = useState([]);
    const [dynamicFields, setDynamicFields] = useState([]);

    const getRequestForm = async (type) => {
        try {
            const response = await fetch(`${IMG_URL}admin/order/get-request-form?type=${type}`, {
            
            
            ...fetchConfig,
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch request form');
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Error fetching request form:', error);
            throw error;
        }
    };
    const handleSubmitRequest = (e) => {
        e.preventDefault();
        if (selectedItems.length === 0) {
            toast.error("Please select at least one product to cancel.");
            return;
        }

        const accountDetails = document.getElementById('accountDetails').value;
        // Check if accountDetails is empty
        if (!accountDetails.trim()) {
            toast.error("Please provide account details.");
            return;
        }

        const requestBody = {
            orderId,
            orderItems: selectedItems.map(index => productOrder.orderDetail[index]._id),
            fields: dynamicFields,
            type: "cancel",
            accountDetails: accountDetails
        };
        console.log("requestBodyrequestBody", requestBody)
        fetch(`${API_BASE_URL}/productOrder/order-cancel-request`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("Cancellation request submitted successfully:", data);
                toast.success("Cancellation request submitted successfully.");
                setCancelPopupVisible(true); // Set the state to true to show the modal
            })
            .catch(error => {
                console.error('Error submitting request:', error);
                toast.error("Error submitting cancellation request. Please try again later.");
            });
    };


    const handleDynamicFields = (ques, response) => {
        dynamicFields[`${ques}`] = response
    }

    useEffect(() => {
        getProductOrder(orderId);
    }, [orderId]);

    useEffect(() => {
        const fetchRequestForm = async () => {
            try {
                const formData = await getRequestForm('cancel');
                setFields(formData.fields);
                const convertedObject = {};
                formData.fields.map(item => { return { [item.label]: "" } }).forEach(item => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    convertedObject[key] = value;
                });
                setDynamicFields(convertedObject)
            } catch (error) {
                console.error('Error fetching request form:', error);
            }
        };

        fetchRequestForm();
    }, []);

    return (
        <MemberLayout>
            <div >
                <div  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <a href="/orders" style={{ color: "black", textDecoration: "none" }}>
                            My Order
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.98384 2.95845C7.22792 2.71437 7.62365 2.71437 7.86772 2.95845L13.3011 8.39178C14.1868 9.27753 14.1868 10.7233 13.3011 11.609L7.86772 17.0423C7.62365 17.2864 7.22792 17.2864 6.98384 17.0423C6.73976 16.7983 6.73976 16.4025 6.98384 16.1584L12.4172 10.7251C12.8148 10.3275 12.8148 9.67326 12.4172 9.27567L6.98384 3.84233C6.73976 3.59825 6.73976 3.20253 6.98384 2.95845Z" fill="#201A18" />
                        </svg>
                        <a href="#" style={{ color: "black", textDecoration: "none" }}>
                            Request for order cancellation
                        </a>
                    </div>
                    <div >
                        <h1 style={{ fontSize: 32, fontWeight: '700' }}>Request for order cancellation</h1>
                        <div className={style.account_order_accordion_list_bar}>
                            {productOrder ? (
                                <>
                                    <div style={{ backgroundColor: '#F7F7F7' }} >
                                        <div className={style.order_lists_bar}>
                                            <div className={style.accordion_lists_bar}>
                                                <div>
                                                    <p>Order No</p>
                                                    <span>{productOrder._id}</span>
                                                </div>

                                            </div>
                                            <div className={style.accordion_lists_bar2}>

                                                <div>

                                                    <p>Status</p>
                                                    <span className={style.orange_text} >{productOrder.status}</span>
                                                </div>

                                            </div>
                                        </div>

                                        <div className={style.account_order_accordion_list_bar} style={{ padding: "0px 20px 0px 20px" }}>
                                            {/* {productOrder.status == "payment failed" ?
                                                <button onClick={() => { navigatorHandler(productOrder._id) }}>
                                                    Retry Payment
                                                </button>
                                                : null} */}

                                            <div style={{ width: '100%' }}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ backgroundColor: "#6F758B", paddingLeft: "20px", textTransform: 'capitalize' }} scope="col">
                                                                <input
                                                                    style={{ backgroundColor: '#6F758B',display:"block" }}
                                                                    type="checkbox"
                                                                    disabled={productOrder?.orderDetail?.some(order => order.status === "cancel request")}
                                                                    onChange={handleSelectAllChange}
                                                                    checked={selectAll}
                                                                />

                                                            </th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Product</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Description</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Price</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Quantity</th>
                                                            <th style={{ textTransform: 'capitalize', backgroundColor: "#6F758B", color: "white" }} scope="col">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ marginTop: "20px" }}>
                                                        {productOrder?.orderDetail?.length > 0 && productOrder?.orderDetail?.map((order, orderIndex) => (
                                                            <tr style={{ backgroundColor: order.status === "cancel request" ? "#F7F7F7" : "white", marginBottom: "20px", borderBottom: "20px solid #F7F7F7" }} key={orderIndex}>
                                                                <th scope="row" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <input
                                                                        style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#6F758B',display:"block" }}
                                                                        type="checkbox"
                                                                        disabled={(order.status == "return request") || (order.status == "cancel request")}
                                                                        onChange={() => handleCheckboxChange(orderIndex)}
                                                                        checked={selectAll || selectedItems.includes(orderIndex)}
                                                                    />
                                                                </th>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <img src={order.productImage ? `${IMG_URL}/${order.productImage}` : null}
                                                                            alt="" style={{ width: "20px", height: "20px", marginRight: "10px" }} />

                                                                        {/* <img src={`${imageURL}/${order.productImage}`} alt="" style={{ width: "20px", height: "20px", marginRight: "10px" }} /> */}
                                                                        <h5 style={{ fontSize: 14, fontWeight: '500' }}>{order.productName}</h5>
                                                                    </div>
                                                                </td>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h4 style={{ fontSize: 14, fontWeight: '500', color: '#6F758B' }}>{order.productDescription && order.productDescription.slice(0, 25)}...</h4>
                                                                </td>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h5 style={{ fontSize: 14, fontWeight: '500' }}>{order.price}SAR</h5>
                                                                </td>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h4 style={{ fontSize: 14, fontWeight: '500' }}>{order.quantity}</h4>
                                                                </td>
                                                                <td style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                                                    <h5 style={{ fontSize: 14, fontWeight: '500', color: '#FD7E14' }}>{order.price}SAR</h5>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {/* </div> */}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div style={{ marginBottom: 20 }}>

                        </div>
                        <div style={{ backgroundColor: '#F7F7F7', paddingInline: '20px', paddingBottom: '20px', paddingTop: '20px', borderRadius: '5px' }}>
                            <p className={style.account_order_title}>Enter your account details:</p>
                            <textarea
                                id="accountDetails"
                                style={{
                                    width: '100%',
                                    paddingin: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                                rows={4}
                                cols={50}
                                placeholder="Enter your account details here..."
                            ></textarea>
                        </div>
                        <DynamicFieldComponents
                            fields={fields}
                            handleDynamicFields={handleDynamicFields}
                            // onSubmit={handleSubmitRequest}
                        />

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <button
                                onClick={handleSubmitRequest}
                                style={{
                                    backgroundColor: '#212F63',
                                    color: '#fff',
                                    padding: '12px 30px',
                                    borderRadius: '5px',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                }}
                            >
                                Submit
                            </button>

                        </div>
                        <Link to={'/orders'}>
                            {isCancelPopupVisible && <CancelPopup title="Your cancel order request submitted successfully!" onClose={() => setCancelPopupVisible(false)} />}
                        </Link>
                        {/* {isCancelPopupVisible && <CancelPopup onClose={() => setCancelPopupVisible(false)} />} */}
                    </div>
                </div>

            </div>

        </MemberLayout >
    )
}