import React, { useState } from 'react';
import Input from '../../input/Input';
import style from "./forgotPasswordCard.module.css";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../config/config';
import { useNavigate } from 'react-router-dom';

export default function ForgotPasswordCard({ toggleModalForLogin = () => { }, updateModalVisibilityForForgotPassWord = null }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/account/request-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email_address: email })
            });
            const data = await response.json();
            if (data.status === 200) {
                toast.success(data?.data?.message);
                setIsOtpSent(true);
            } else {
                toast.error(data?.data?.message);
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };


    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }
        try {
            const response = await fetch(`${API_BASE_URL}/account/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email_address: email, otp, new_password: newPassword })
            });
            const data = await response.json();

            if (response.status === 200) {
                console.log('Response:', data.data.message);
                if (data.data.message === 'Password reset successfully') {
                    toast.success(data.data.message);
                    setShowSuccessMessage(true);
                    return;
                }
            }

            toast.error(data.message || 'Something went wrong');
        } catch (error) {
            console.error('Error:', error);
            toast.error('Something went wrong');
        }
    };


    return (
        <div className={style.card_wrapper}>
            {showSuccessMessage ? (
                // <div style={{ alignItems: 'center',justifyItems:'center', margin: '20px auto', maxWidth: '100%' }}>
                // <<div className={style.card_wrapper}>
                <div>
                    <span className={style.heading}>{t('PassRecovery')}</span>
                    <h2 className={style.heading_description}>{t('PassForget')}</h2>

                    <section className={style.description_text}>
                        <p>{t('PassReset')}</p>
                        <ul>
                            <li>{t('PassInst1')}</li>
                            <li>{t('PassInst2')}</li>
                        </ul>
                    </section>
                    <div className={style.success_container} style={{ textAlign: 'center', maxWidth: '100%', margin: '0 auto' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" style={{ marginBottom: '20px', fill: '#4caf50' }}>
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15l-5-5 1.41-1.41L11 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
    <div style={{ marginBottom: '10px' }}>
        <span className={style.success_message} style={{ fontSize: '18px', fontWeight: 'bold' }}>Password reset successfully!</span>
    </div>
    <div style={{ marginBottom: '20px' }}>
        <p className={style.success_description} style={{ fontSize: '16px' }}>You can now sign in with your new password.</p>
    </div>
    <div>
        <button className={style.btn1} style={{ backgroundColor: '#0e1731', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '2px', cursor: 'pointer', fontSize: '16px' }} onClick={() => toggleModalForLogin(true)}>Sign In</button>
    </div>
</div>

                </div>

            ) : (
                <>
                    <span className={style.heading}>{t('PassRecovery')}</span>
                    <h2 className={style.heading_description}>{t('PassForget')}</h2>

                    <section className={style.description_text}>
                        <p>{t('PassReset')}</p>
                        <ul>
                            <li>{t('PassInst1')}</li>
                            <li>{t('PassInst2')}</li>
                        </ul>
                    </section>

                    <form className={style.form} onSubmit={isOtpSent ? handleResetPassword : handleSendOtp}>
                        <div className={style.input_container} style={{ marginBottom: '10px' }}>

                            <label className={style.input_label}>{t('LoginPhone')}</label>
                            <input
                                type='email'
                                placeholder={t('LoginPhone')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className={style.input_field}
                            />
                        </div>
                        {isOtpSent ? (
                            <>
                                <div className={style.input_container} >
                                    <label className={style.input_label}>Enter OTP</label>
                                    <input
                                        type='number'
                                        placeholder='Enter Otp'
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        required
                                        className={style.input_field}
                                    />
                                </div>
                                <div className={style.input_container}>
                                    <label className={style.input_label}>New Password</label>
                                    <input
                                        type='password'
                                        placeholder='New Password'
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                        className={style.input_field}
                                    />
                                </div>
                                <div className={style.input_container}>
                                    <label className={style.input_label}>Confirm Password</label>
                                    <input
                                        type='password'
                                        placeholder='Confirm Password'
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                        className={style.input_field}
                                    />
                                </div>
                            </>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <input className={style.btn1} type="submit" value={t('PassRecover')} />
                            <p className={style.btn2} onClick={() => { toggleModalForLogin(true) }}>Return to Sign In</p>
                        </div>
                        
                        )}
                        {isOtpSent && (
                            <input className={style.btn1} type="submit" value='Reset Password' />
                        )}
                    </form>
                </>
            )}

        </div>
    );
}
