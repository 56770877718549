import React from 'react';
import Input from '../../input/Input';
import style from "./popupMessage.module.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';

export default function PopupMessage({updateModalIsOpenForPopupMessage=null,updateToken=null,logoutFunction=null}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const clichLogoutFunction = () => {
        
        localStorage.clear();
        updateToken(false);
        updateModalIsOpenForPopupMessage(false);
        navigate("/")

    }
    const clickCancleFunction = () => {
        
        logoutFunction(false)

      }
    return (
        <div className={style.card_wrapper}>

            
            <h2 className={style.heading_description}>{t('AlertForLogout')}</h2>

                <div className={style.card_button_boxes}>
                <button onClick={clickCancleFunction} className='btn1'>{t('Cancel')}</button>
            <button onClick={clichLogoutFunction} className={`btn1 ${style.cancle_btn}`}>{t('Logout')}</button>
                </div>
           
        </div>
    )
}
