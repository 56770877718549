import React, {useState, useEffect} from 'react'
import style from "./privacy.module.css";
import { useTranslation } from "react-i18next";
export default function Privacy() {
   const { t } = useTranslation();
   // https://admin-anycarpart.go-demo.com/api/v1/settings/privacyPolicy

   const appURL = 'https://admin-anycarpart.go-demo.com/api/v1/settings/privacyPolicy';
   const [privacy, setPrivacy] = useState('');
 
   useEffect(() => {
     const fetchPrivacyPolicy = async () => {
       try {
         const response = await fetch(appURL);
         if (!response.ok) {
           throw new Error('Failed to fetch privacy policy');
         }
         const data = await response.json();
         setPrivacy(data.data.privacyPolicy[0].privacyPolicy);
         console.log(data.data.privacyPolicy[0].privacyPolicy)
       } catch (error) {
         console.log(error);
       }
     };
 
     fetchPrivacyPolicy();
   }, []);

    return (
        <div className={style.main_wrapper}>
         <div className={style.privacy_policy_text}>

         <div>
      <div dangerouslySetInnerHTML={{ __html: privacy }} />
    </div>
                <h1>{t('PrivacyPolicy')}</h1>
                <p>rrrrrNunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
        </div>
    )
}
