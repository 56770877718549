/**
 * Configuration object for Fetch API requests with multipart/form-data
 */
const fetchMultipartConfig = () => {
    const config = {
        method: 'POST',
        redirect: 'follow',
        credentials: 'same-origin',
        headers: {
            // Custom header to indicate the type of content being sent
            'X-Content-Type': 'multipart/form-data',
            'fetch-req': true // Custom flag to identify fetch requests
        }
    };

    if (typeof window !== 'undefined') {
        const token = localStorage.getItem('token');
        const lang = localStorage.getItem('AnyCarPartLang');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        if (lang) {
            config.headers['lang'] = lang;
        }
    }

    return config;
};

export default fetchMultipartConfig;
