import React from 'react';
import LatestNewsCard from '../../components/latest-news-card/LatestNewsCard';
import Pagination from '../../components/paginations/Pagination';
import style from './article.module.css';


export default function Articles() {
    return (
        <main className={style.article_main_wrapper}>
            <h1>Guides & Articles</h1>
            <div className={style.article_section}>
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
                <LatestNewsCard />
            </div>
            <Pagination classNameForLoadMoreText={style.load_more_text} viewAllBtnText='Load more' svgIcon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_540_2232)">
                    <path d="M9.75048 12.129L13.7735 8.106L14.834 9.1665L9.00048 15L3.16698 9.1665L4.22748 8.106L8.25048 12.129V3H9.75048V12.129Z" fill="#212F63" />
                </g>
                <defs>
                    <clipPath id="clip0_540_2232">
                        <rect width="18" height="18" fill="white" transform="matrix(0 1 -1 0 18 0)" />
                    </clipPath>
                </defs>
            </svg>
            } />
        </main>
    )
}
