import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/home/Home';
import ScrollToTop from './components/ScrollToTop'
import { toast } from "react-toastify"
import { GoogleOAuthProvider } from '@react-oauth/google';
// product page
import Product from './pages/product/Product';
import ProductDetails from './pages/product-details/ProductDetails';
import Footer from "./components/footer/Footer";
import ChatButton from './components/chatButton/ChatButton';
// accounts
import Account from './pages/accounts/Account';
import Header from './components/header/header';
import CustomersReviews from './pages/customer-reviews/CustomersReviews';
import MyOrder from './pages/accounts/MyOrder/MyOrder';
import Faqs from './pages/faqs/Faqs';
import Giftcard from './pages/giftcard/Giftcad';
import Coupancode from './pages/coupancods/coupancode';
import AbouttUs from './pages/aboutus/AbouttUs';
import NewsDetails from './pages/details/NewsDetails';
import Shipping from './pages/shipping_page/shipping_page';
import Privacy from './pages/privacypage/privacy';
import Pricematch from './pages/pricematch/Pricematch';
import Shopping from './pages/shopping-cart/shoppingcart';
import NewsAndGuides from './pages/news-guides/NewsAndGuides';
import TrackOrder from './pages/track-order/TrackOrder';
import HelpCenter1 from './pages/help-center1/HelpCenter1';
import Articles from './pages/articles/Articles';
import ContactUs from './pages/contact-us/ContactUs';
import Checkout from './pages/checkout-page/checkout';
import Myaccount from './pages/my-order/myaccount'
import BuyWholesale from './pages/buy-wholesale/BuyWholesale';
import Checkoutpickup from './pages/checkoutpickup/checkoutpickup'
import Sucessmessage from './pages/success message/mesage'
import Checkoutshipping from './pages/checkout-shipping/Checkoutshipping'
import Paymentgate from './pages/checkoutpaymentgate/Paymentgate'
import Wishlistpage from './pages/wishlist-page/wishlist';
import Receiptpage from './pages/recieptpage/Receipt'
import Changepassword from './pages/changepasswordpage/changepassword.jsx';
import React, { Suspense, useState } from 'react';
import i18n from './i18n';
import LocaleContext from './LocaleContext';
import { Helmet } from 'react-helmet';
import Profile from './pages/profile/profile';
import { ToastContainer } from 'react-toastify';
import PaymentForm from './pages/PaymentForm/PaymentForm';
import Payment_sucess from './pages/Payment_sucess';
import VisaCheckoutComponent from './pages/VisaCheckoutComponent/VisaCheckoutComponent';
import 'react-toastify/dist/ReactToastify.css';
import CancelOrderPage from './pages/my-order/cancelOrderPage';
import ReturnOrderPage from './pages/my-order/returnOrderPage';
import SpecialOrderPage from './pages/specialOrder/specialOrder.jsx';
import MySpecialOrders from './pages/specialOrder/myspecialorder.jsx';
import VerifyEmail from './pages/accounts/VerifyEmail.jsx';
function App() {
  const [locale, setLocale] = useState(i18n.language);
  const [data, setdata] = useState()

  const [cartWishCount, setCartWishCount] = useState();
  const [cartCount, setcartCount] = useState()
  const [quantity, setQuantity] =useState()

  const [localVinNumber, setlocalVinNumber] = useState()
  const [category, setsubcategory] = useState([])
  const [productdata, setproductdata] = useState([])
  const [message, setMessage] = useState('');
  console.log(message, "message")
  i18n.on('languageChanged', (lng) => {
    console.log(lng, "mistake")
    console.log(i18n.language, "i mmmmmmm")
    setLocale(i18n.language)
  });

  return (
    <>
      <GoogleOAuthProvider clientId="304304597733-8nquelhhlt6hgitk84j5vf8vu9hoebmj.apps.googleusercontent.com">
        <LocaleContext.Provider value={{ locale, setLocale }}>
          <ToastContainer />
          <Header 
      cartWishCount={cartWishCount}
      setCartWishCount={setCartWishCount}
            setcartCount={setcartCount}
            cartCount={cartCount}
            setdata={setdata}
            setlocalVinNumber={setlocalVinNumber}
            setsubcategory={setsubcategory}
            setproductdata={setproductdata}
            message={message} />
          {/* <ChatButton /> */}
          <Helmet htmlAttributes={{
            lang: locale,
            dir: locale === 'en' || locale === 'en-US' ? 'ltr' : 'rtl'
          }} />
          <Routes>
            {/* Auth */}
            <Route exact index path="/" element={<Home />} />
            <Route exact index path="/Checkoutpickup" element={<Checkoutpickup />} />
            <Route exact index path="/Sucessmessage" element={<Sucessmessage />} />
            <Route exact index path="/shopping-cart" element={<Shopping 
                 setcartCount={setcartCount}
                 cartCount={cartCount}
                 cartWishCount={cartWishCount}
                 setCartWishCount={setCartWishCount}
            
                quantity={quantity}
                setQuantity={setQuantity}
            
            />} />
            <Route exact index path="/orders" element={<Myaccount />} />
            <Route exact index path='/order/cancel/:orderId' element={<CancelOrderPage />} />
            <Route exact index path='/order/return/:orderId' element={<ReturnOrderPage />} />
            <Route exact index path="/Checkout" element={<Checkout />} />
            <Route exact index path="/Couponcode" element={<Coupancode />} />
            <Route exact index path="/Pricematch" element={<Pricematch />} />
            <Route exact index path="/Privacy" element={<Privacy />} />
            <Route exact path="/news-details" element={<NewsDetails />} />
            <Route exact path="/shipping" element={<Shipping />} />
            <Route exact path="/giftcard" element={<Giftcard />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/aboutus" element={<AbouttUs />} />
            <Route exact path="/product"  element={<Product data={data} localVinNumber={localVinNumber} category={category} productdata={productdata} setMessage={setMessage} setsubcategory={setsubcategory}
              setcartCount={setcartCount}
              cartCount={cartCount}
              cartWishCount={cartWishCount}
              setCartWishCount={setCartWishCount}
            />} />
            <Route exact path="/accounts" element={<Account />} />
            <Route exact path="/verify-email" element={<VerifyEmail />} />
            <Route exact path="/customers-reviews" element={<CustomersReviews />} />
            <Route exact path="/product-details" element={<ProductDetails
              setcartCount={setcartCount}
              cartCount={cartCount}
              cartWishCount={cartWishCount}
              setCartWishCount={setCartWishCount}
            />} />
            {/* <Route exact path="/MyOrder" element={<MyOrder/>} /> */}
            <Route exact path="/articles" element={<Articles />} />
            <Route exact path="/news-guide" element={<NewsAndGuides />} />
            <Route exact path="/track-order" element={<TrackOrder />} />
            <Route exact path="/help-center1" element={<HelpCenter1 />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/buy-wholesale" element={<BuyWholesale />} />
            <Route exact path="/Checkoutshipping" element={<Checkoutshipping />} />
            <Route exact path="/Paymentgate" element={<Paymentgate />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/Wishlistpage" element={<Wishlistpage
                 setcartCount={setcartCount}
                 cartCount={cartCount}
                 cartWishCount={cartWishCount}
                 setCartWishCount={setCartWishCount}
            
            />} />
            <Route exact path="/Receiptpage" element={<Receiptpage />} />
            <Route exact path="/Changepassword" element={<Changepassword />} />
            <Route exact path='/paymentForm/:id' element={<PaymentForm />} />
            <Route exact path='/payment/sucess' element={<Payment_sucess />} />
            <Route exact path='/payment' element={<VisaCheckoutComponent />} />
            <Route exact path='/special-order' element={<SpecialOrderPage />} />
            <Route exact path='/my-special-orders' element={<MySpecialOrders />} />
          </Routes>
          {/* <ScrollToTop /> */}
          <Footer />
        </LocaleContext.Provider>
      </GoogleOAuthProvider>
    </>
  );
}
export default App;
