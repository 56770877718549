import React from 'react';
import style from './heroImage.module.css'

export default function HeroImage({ HeroImage = ``, Heading = `` }) {
    return (

        <section className={style.hero_section_wrapper}>

            <div className={style.hero_image_div} style={{
                backgroundImage: `url(${HeroImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '250px',
               
            }}>

                <h1 className={style.hero_heading}>{Heading}</h1>

            </div>

        </section>
    )
}
