import React from 'react';
import style from './checkoutpickup.module.css';
// import { Select, Stack } from '@chakra-ui/react'

export default function Checkoutpickup() {
    return (
        <div className={style.main_wrapper}>
            <div className={style.checkout_cart}>
                <h1>Checkout</h1>
                <p>3 item(s) in Cart</p>
            </div>
            <div className={style.checkout_cart_coupancode}>
                <div className={style.checkout_cart_heading}>
                    <img src="/assets/Group 569 (1).png" alt="" />
                    <span>Have a coupon? <h4>Click here to enter your code</h4></span>
                </div>
                <div>
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
            <div className={style.checkout_cart_customer_product}>
                <div className={style.checkout_cart_customer_products}>
                    <div className={style.checkout_cart_customer}>
                        <span><i class="fa-solid fa-check"></i> <h3>Customer</h3></span>
                        <div className={style.checkout_form_box}>
                            <h4>email@adress.com</h4>
                        </div>
                    </div>
                    <div className={style.checkout_cart_gray_box}>
                        <span>2 <p>Pickup from store or indicate shipping option</p></span>
                        <h5>Personal information</h5>
                        <div className={style.input_fileds}>
                            <div>
                                <label for="fname">First Name:</label>
                                <input type="text" id="fname" placeholder="First Name" name="fname" />
                            </div>
                            <div>
                                <label for="lname">Last Name:</label>
                                <input type="text" id="lname" placeholder="Last Name" name="lname" />
                            </div>
                        </div>
                        <div className={style.input_filed_box}>
                            <label for="lname">Company Name (Optional)</label>
                            <input type="text" id="lname" placeholder="Company Name (Optional)" name="lname" />
                        </div>
                        <div className={style.input_filed_box}>
                            <label for="lname">Phone Number</label>
                            <input type="text" id="lname" placeholder="Phone Number" name="lname" />
                        </div>
                        <div className={style.custom_radio_wrap}>
                            <h3>Shipping method</h3>
                            <div className={style.shipping_method}>
                                <form>
                                <div className={style.form_group}>
                                        <div className={style.comment_form_cookies_consent} >
                                            <input id={style.wp_comment_cookies_consent} name="wp-comment-cookies-consent" type="checkbox" value="yes" />
                                            <h6 className={style.label_text}>Pickup from Store</h6>
                                        </div>
                                    </div>
                                    <div className={style.select_boxes}>
                                        <div className={style.select_boxes1}>
                                            <div>
                                                <h6>Select city</h6>
                                            </div>
                                            <div className={style.select}>
                                                <select>
                                                    <option value="1">Select City</option>
                                                    <option value="2">No JS</option>
                                                    <option value="3">Nice!</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={style.select_boxes1}>
                                            <div>
                                                <h6>Select Store</h6>
                                            </div>
                                            <div className={style.select}>
                                                <select>
                                                    <option value="1"> Select Store</option>
                                                    <option value="2">No JS</option>
                                                    <option value="3">Nice!</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={style.deliver_step}>
                            <div className={style.comment_form_cookies_consent} >
                                            <input id={style.wp_comment_cookies_consent} name="wp-comment-cookies-consent" type="checkbox" value="yes" />
                                            <h6 className={style.label_text}>Deliver at your Door Step</h6>
                                        </div>
                            </div>
                            <div className={style.order_method}>
                                <h3>Order comments</h3>
                                <input type="text" id="lname" placeholder="Leave your comments (optional)" name="lname" />
                                <button>Continue</button>
                            </div>
                        </div>
                    </div>
                    <div className={style.checkout_cart_gray_box1}>
                        <span>3<p>Payment</p></span>
                    </div>
                </div>
                <div className={style.checkout_order_summery}>
                    <h2>Order Summary</h2>
                    <div className={style.checkout_items_products}>
                        <div className={style.checkout_items_oil_filters}>
                            <div>
                                <img src="/assets/Rectangle 114 (1).png" alt="" />
                            </div>
                            <div >
                                <div>
                                    <h5>Oil Filter</h5>
                                </div>
                                <div>
                                    <h4>LR164821</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5>98.00 SAR</h5>
                            </div>
                            <div>
                                <h4>LR164821</h4>
                            </div>
                        </div>
                    </div>
                    <div className={style.checkout_items_products}>
                        <div className={style.checkout_items_oil_filters}>
                            <div>
                                <img src="/assets/Rectangle 114 (1).png" alt="" />
                            </div>
                            <div >
                                <div>
                                    <h5>Oil Filter</h5>
                                </div>
                                <div>
                                    <h4>LR164821</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5>98.00 SAR</h5>
                            </div>
                            <div>
                                <h4>LR164821</h4>
                            </div>
                        </div>
                    </div>
                    <div className={style.checkout_items_products}>
                        <div className={style.checkout_items_oil_filters}>
                            <div>
                                <img src="/assets/Rectangle 114 (1).png" alt="" />
                            </div>
                            <div >
                                <div>
                                    <h5>Oil Filter</h5>
                                </div>
                                <div>
                                    <h4>LR164821</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5>98.00 SAR</h5>
                            </div>
                            <div>
                                <h4>LR164821</h4>
                            </div>
                        </div>
                    </div>
                    <div className={style.order_summary_section}>
                        <div className={style.order_summary}>
                            <div className={style.order_summary_lists}>
                                <p>Sub Total:</p>
                                <p>Estimated Shipping Cost:</p>
                                <p>Coupon card:</p>
                                <p>VAT:</p>
                                <h2>Total:</h2>
                            </div>
                            <div className={style.order_summary_lists1}>
                                <p>1,392 SAR</p>
                                <p>0 SAR</p>
                                <p>-</p>
                                <p>-</p>
                                <h2>1,392 SAR</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
