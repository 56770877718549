import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import style from "./Faqs.module.css";
import BottomHero from '../../components/bottom-hero/BottomHero';
export default function ColorTabs() {
    const [value, setValue] = React.useState('one');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={style.main_wrapper}>
            <h1 className={style.faq_text} >faq</h1>
            <div className={style.search_input_box}>
                <input type="text" placeholder="Ask a question" />
                <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div className={style.faq_tabs_lists}>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"  >
                        <Tab value="one" label="General" />
                        <Tab value="two" label="Payments" />
                        <Tab value="three" label="Services" />
                        <Tab value="four" label="Tires" />
                        <Tab value="five" label="Refund" />
                        <Tab value="six" label="Contact" />
                    </Tabs>
                </Box>
            </div>
            <div className={style.shipping_accordian_bar1}>
             <Accordion className={style.shipping_Accordion_text1}>
                 <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header">
                     <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography>
                     Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus. 
                     </Typography>
                 </AccordionDetails>
             </Accordion>
             <Accordion className={style.shipping_Accordion_text1}>
                 <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel2a-content"
                     id="panel2a-header">
                     <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography>
                     Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus. 
                     </Typography>
                 </AccordionDetails>
             </Accordion>
             <Accordion className={style.shipping_Accordion_text1}>
                 <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"  >
                     <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography>
                     Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus. 
                     </Typography>
                 </AccordionDetails>
             </Accordion>
             <Accordion className={style.shipping_Accordion_text1}>
                 <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel2a-content"
                     id="panel2a-header" >
                     <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography>
                     Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus. 
                     </Typography>
                 </AccordionDetails>
             </Accordion>
             <Accordion className={style.shipping_Accordion_text1}>
                 <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel2a-content"
                     id="panel2a-header">
                     <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography>
                     Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus. 
                     </Typography>
                 </AccordionDetails>
             </Accordion>
         </div>
         <div className={style.bottom_image}>
         <BottomHero />
         </div>
        </div>
    );
}


