import React from 'react';
import style from './input.module.css'
import { useTranslation } from "react-i18next";
export default function Input({ type = ``, placeholder = ``, label = ``, className = ``, id = ``, required = false, onChangeHandler=()=>{}, name=``, value=`` }) {
    const { t } = useTranslation();
    return (
        <section id={id} className={`${style.input_Wrapper} ${className}`}>
            {
                label ? <label htmlFor="">{label}{required? `: *`: ``}</label> : ``
            }
            {
                (() => {
                    if (type === `text` || (type === `number`) || (type === `email`) || (type === `password`)) {
                        return (
                            <input type={type} placeholder={placeholder} onChange={onChangeHandler} name={name} value={value} />
                        )
                    } else if (type === `textarea`) {
                        return (
                            <textarea id="w3review"  name="w3review" rows="4" cols="50" placeholder="Enter your message

                           ">

                            </textarea>

                        )
                    } else if (type === `select`) {
                        return (
                            <select name="cars"  placeholder={placeholder}>
                                <option selected value="volvo">{placeholder}</option>
                                <option value="volvo">{t('ContactUsOption')}</option>
                                <option value="saab">{t('ContactUsOption')}</option>
                                <option value="opel">{t('ContactUsOption')}</option>
                                <option value="audi">{t('ContactUsOption')}</option>
                            </select>
                        )
                    }

                })()
            }

        </section>
    )
}
