import React, { useState } from 'react';
import style from './trackOrder.module.css';
import BottomHero from '../../components/bottom-hero/BottomHero';
import BottomHeroImage from '../../assets/images/CustomerReviewsbottomHero.png'
import { useTranslation } from "react-i18next";
import getFetchConfig from '../../utils/getFetchConfig';
import { API_BASE_URL } from '../../config/config';
import { ClipLoader } from 'react-spinners';

export default function TrackOrder() {
    const { t } = useTranslation();
    const [orderId, setOrderId] = useState('');
    const [email, setEmail] = useState('');
    const [orderHistory, setOrderHistory] = useState([]); // Array to hold order history data
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const fetchConfig = getFetchConfig(); // Assuming this fetches necessary headers

    const handleTrackOrder = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Set loading to true when API call starts

        try {
            const response = await fetch(`${API_BASE_URL}/productOrder/get-order-status-history`, {
                ...fetchConfig,
                method: 'POST',
                body: JSON.stringify({
                    orderId, // Assuming the API expects `orderId` instead of `shippingAdressId`
                    email,
                }),
            });

            if (response.ok) { // Check for successful HTTP response (status code 200-299)
                const responseData = await response.json();
                if (responseData.success) {
                    setOrderHistory(responseData.statusHistory);
                    setErrorMessage(null); // Clear any previous error messages
                } else {
                    setOrderHistory([]); // Clear order history if the API call fails
                    setErrorMessage(responseData.message || 'API error'); // Set error message if API call fails
                }
            } else {
                setOrderHistory([]); // Clear order history on network error
                setErrorMessage('Network error'); // Handle non-2xx HTTP responses
            }
        } catch (error) {
            console.error('Error fetching order history:', error);
            setOrderHistory([]); // Clear order history on error
            setErrorMessage('An error occurred. Please try again later.');
        } finally {
            setIsLoading(false); // Set loading to false when API call is complete
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'inprogress':
                return '#FF5722';
            case 'onhold':
                return '#FFC107';
            case 'shipped':
                return '#03A9F4';
            case 'delivered':
                return '#8BC34A';
            case 'completed':
                return '#4CAF50';
            case 'payment failed':
                return '#F44336';
            default:
                return '#ddd';
        }
    };

    const formatStatusLabel = (status) => {
        switch (status) {
            case 'inprogress':
                return 'In Progress';
            case 'onhold':
                return 'On Hold';
            case 'shipped':
                return 'Shipped';
            case 'delivered':
                return 'Delivered';
            case 'completed':
                return 'Completed';
            case 'payment failed':
                return 'Payment Failed';
            default:
                return status;
        }
    };

    return (
        <main className={style.main_wrapper}>
            <h1>{t('TrackOrderTitle')}</h1>
            <p>
                Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
            </p>
            <p>Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>

            <form id={style.track_form} onSubmit={handleTrackOrder}>
                <input
                    type='text'
                    placeholder={t('TrackOrderEmailInput')}
                    label={t('TrackOrderEmail')}
                    value={email}
                    style={{ height: '40px', padding: '10px' }}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type='text'
                    placeholder={t('TrackOrderNumberInput')}
                    label={t('TrackOrderNumber')}
                    value={orderId}
                    style={{ height: '40px', padding: '10px' }}
                    onChange={(e) => setOrderId(e.target.value)}
                    required
                />
                <button id={style.span_2} className={style.submit_btn}>{t('TrackOrderNumberbtn')}</button>
            </form>
            {errorMessage &&
                <p style={{ color: 'red', fontSize: '16px', marginBottom: '10px' }}>
                    {errorMessage}
                </p>
            }

<div style={{ margin: '20px 0', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
    {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <ClipLoader color="#212F63" size={50} />
            <p style={{ marginLeft: '10px', fontWeight: 'bold', color: '#f39c12' }}>Loading...</p>
        </div>
    ) : orderHistory.length > 0 ? (
        <>
            <p style={{ textAlign: 'center', color: '#4CAF50', fontWeight: 'bold' }}>Your order history is below and we have also sent an email to you.</p>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                {orderHistory.map((historyItem) => (
                    <li key={historyItem._id} style={{ display: 'flex', alignItems: 'center', padding: '10px 0', position: 'relative' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
                            <div style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: getStatusColor(historyItem.status), zIndex: 1 }}></div>
                            <div style={{ width: '2px', height: '100%', backgroundColor: '#ddd', position: 'absolute', top: 0, zIndex: 0 }}></div>
                        </div>
                        <div>
                            <p style={{ fontWeight: 'bold', margin: '0 0 5px 0' }}>{formatStatusLabel(historyItem.status)}</p>
                            <p style={{ color: '#999', fontSize: '0.8rem', margin: 0 }}>{new Date(historyItem.updatedAt).toLocaleString()}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    ) : null}
    {orderHistory && orderHistory.length === 0 && !isLoading && (
        <p style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>No order history found for the above order.</p>
    )}
</div>

            <BottomHero HeroImage={BottomHeroImage} />
        </main>
    );
}
