import React, { useState, useEffect } from 'react';
// import { Select, Stack } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import style from './Shoppingcart.module.css';
import { useSearchParams, useNavigate } from 'react-router-dom';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import Loader from '../../components/loader/Loader';
import emptyImage from '../../assets/images/no-product-found1.png'
import { toast } from 'react-toastify';
import { API_BASE_URL, IMG_URL } from '../../config/config';
import { updateCartCount } from '../../utils/commonFunctions';
// import { applyCoupon } from '../../../../anycarpart-admin/controllers/api/productOrder/productOrder';
import NodDataImg from '../../assets/images/datanotfoundImg.png'
import AddToWishlistButton from '../../components/favoriteProducts/AddToWishlistButton';



export default function Shoppingcart({ productId = null, wishDetail = null, subcategoryId = null, productTitle, productPrice, productCurrency, productThumbnailShort, productSku, setcartCount, cartWishCount, setCartWishCount, cartCount, quantity, product, setQuantity }) {
    const [searchParams, setSearchParams] = useSearchParams();
    // const categoryId = searchParams.get('categoryId');
    // const subCategoryId = searchParams.get('subCategoryId');
    const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
    const imageURL = `https://admin-anycarpart.go-demo.com`;
    const fetchConfig = getFetchConfig();
    const [loader, setLoader] = useState(true);
    const [allCartProduct, setAllCartProduct] = useState([]);
    const [cartOrderSummary, setCartOrderSummary] = useState({ subTotal: 0, discount: 0, shippingCost: 0, vat: 0, total: 0, afterDiscount: 0 });
    const [applyCoupon, setapplyCoupon] = useState()
    const [message, setmessage] = useState()
    const [totalProductPrice, settotalProductPrice] = useState()
    const navigate = useNavigate();
    const Cart = window.localStorage.getItem('ProductCart')
    const CartProduct = Cart ? JSON.parse(Cart) : [];
    const wishList = window.localStorage.getItem('Product')
    const wishListProduct = wishList ? JSON.parse(wishList) : []
    const tokenExists = window.localStorage.getItem("token")


    const fetchAllCartProduct = () => {
        setLoader(true);
        fetch(`${API_BASE_URL}/cart/getAllCartProduct`, {
            ...fetchConfig,
            method: `POST`,
        })
            .then(handleFetchErrors)
            .then((dataAllCartProduct) => {
                const productsWithThumbnail = dataAllCartProduct.data.product.map(product => ({
                    ...product,
                    thumbnail: product.thumbnail ? `${IMG_URL}${product.thumbnail[0]}` : null
                }));
                console.log('productsWithThumbnail......', productsWithThumbnail)
                // console.log('---x----x----x----x----');
                console.log(dataAllCartProduct.data, "kommmm");
                setAllCartProduct(dataAllCartProduct.data.product);
                // setCartOrderSummary(dataAllCartProduct.data);
                setCartOrderSummary({ subTotal: dataAllCartProduct.data.subTotal, discount: 0, shippingCost: dataAllCartProduct.data.shippingCost, vat: dataAllCartProduct.data.vat, total: dataAllCartProduct.data.total })
                setLoader(false);
            })
            .catch(standardFetchHandlers.error);
    };

    const updateCartPrduct = (quantity, productById) => {
        fetch(`${appURL}/cart/updateCart`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                id: productById,
                quantity: quantity
            })
        })
            .then(handleFetchErrors)
            .then((dataupdateCartPrduct) => {
                // window.location.reload();
                console.log(dataupdateCartPrduct.data, "mmmmmmmmmmmmmmmm");
                // // console.log('---x----x----x----x----')
                // // console.log(dataupdateCartPrduct.data);
                // fetchAllCartProduct();


            })
            .catch(standardFetchHandlers.error);
    };

    const removeCart = (productIdToRemove) => {
        if (tokenExists) {
            fetch(`${API_BASE_URL}/cart/remove/${productIdToRemove}`, {
                ...fetchConfig,
                method: `delete`,
            })
                .then((dataupdateCartPrduct) => {
                    toast.success("product remove from to cart sucessfully")
                    // fetchAllCartProduct()
                    fetchAllCartProduct()
                    updateCartCount(setcartCount, setCartWishCount);

                    // window.location.reload()
                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            const updatedProducts = CartProduct.filter(product => product._id !== productIdToRemove);
            setAllCartProduct(updatedProducts)
            const newProduct = JSON.stringify(updatedProducts)
            window.localStorage.setItem('ProductCart', newProduct)
            toast.success("product remove from to cart sucessfully")
            // window.location.reload()
            updateCartCount(setcartCount, setCartWishCount);

        }

    };
    useEffect(() => {
        console.log("Quantity updated:", quantity);
        updateCartCount(setcartCount, setCartWishCount, setQuantity);

    }, [quantity]);

    const incNum = (allproductByFilter, quantity) => {
        if (tokenExists) {
            console.log(allproductByFilter)
            fetch(`${API_BASE_URL}/cart/updateCart`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    id: allproductByFilter.productId,
                    quantity: allproductByFilter.quantity + 1,
                    add: "add",
                })
            })
                .then(handleFetchErrors)
                .then((dataupdateCartPrduct) => {
                    // fetchAllCartProduct()
                    // window.location.reload()
                    fetchAllCartProduct()
                    updateCartCount(setcartCount, setCartWishCount, setQuantity);
                    console.log('kkkkkkkkkkk', allproductByFilter.quantity)

                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            let newNum = Number(quantity) + 1;
            let product = allCartProduct.filter((data) => data._id === allproductByFilter._id)
            for (let i = 0; i < CartProduct.length; i++) {
                if (CartProduct[i]._id === allproductByFilter._id) {
                    // If the product ID matches, update quantity and total price
                    CartProduct[i].quantity += 1;
                    CartProduct[i].price = CartProduct[i].price + (allproductByFilter.price / (CartProduct[i].quantity - 1));
                }
            }
            console.log(CartProduct, "CartProduct")
            let newproduct = JSON.stringify(CartProduct)
            window.localStorage.setItem('ProductCart', newproduct)
            // window.location.reload()
            updateCartCount(setcartCount, setCartWishCount, setQuantity);
            console.log('kkkkkkkkkkk', allproductByFilter.quantity)

        }
    };
    const decNum = (allproductByFilter, quantity) => {
        if (tokenExists) {
            console.log(allproductByFilter)
            fetch(`${API_BASE_URL}/cart/updateCart`, {
                ...fetchConfig,
                method: `POST`,
                body: JSON.stringify({
                    id: allproductByFilter.productId,
                    quantity: allproductByFilter.quantity - 1,
                    add: "minus",
                })
            })
                .then(handleFetchErrors)
                .then((dataupdateCartPrduct) => {
                    fetchAllCartProduct()
                    // window.location.reload()
                    updateCartCount(setcartCount, setCartWishCount, setQuantity);

                })
                .catch((error) => {
                    console.log(error.message)
                });
        } else {
            let newNum = Number(quantity) - 1;
            let product = allCartProduct.filter((data) => data._id === allproductByFilter._id)
            for (let i = 0; i < CartProduct.length; i++) {
                if (CartProduct[i]._id === allproductByFilter._id) {
                    // If the product ID matches, update quantity and total price
                    CartProduct[i].quantity -= 1;
                    CartProduct[i].price = CartProduct[i].price - (allproductByFilter.price / (CartProduct[i].quantity + 1));
                }
            }
            console.log(CartProduct, "CartProduct")
            let newproduct = JSON.stringify(CartProduct)
            window.localStorage.setItem('ProductCart', newproduct)
            // window.location.reload()
            updateCartCount(setcartCount, setCartWishCount, setQuantity);

        }

    }

    const tokenExits = window.localStorage.getItem('token')
    const [isHeartActive, setIsHeartActive] = useState(false);
    const updateFavoriteProduct = (event, product) => {
        event.stopPropagation();


        if (tokenExists) {
            fetch(`${API_BASE_URL}/product/favoriteProduct`, {
                ...fetchConfig,
                method: 'POST',
                body: JSON.stringify({
                    productId: product._id
                })
            })
                .then(handleFetchErrors)
                .then((dataupdateCartProduct) => {
                    console.log(dataupdateCartProduct.data, "product added");
                    if (dataupdateCartProduct.data.message === "Something went wrong") {
                        toast.success("Product remove from wishlist.");
                    } else {
                        toast.success("Product added to wishlist.");
                    }
                    // Update cart count
                    updateCartCount(setcartCount, setCartWishCount);
                })
                .catch((error) => {
                    console.log(error.message);
                });
        } else {
            let data = window.localStorage.getItem("Product");
            if (!data) {
                const newProduct = JSON.stringify([product]);
                window.localStorage.setItem("Product", newProduct);
                setIsHeartActive(true);
                toast.success("Product added to wishlist.");
            } else {
                const existingProducts = JSON.parse(data);
                const isProductInWishlist = existingProducts.filter((item) => item._id === product._id);
                if (isProductInWishlist.length === 0) {
                    existingProducts.push(product);
                    const newProduct = JSON.stringify(existingProducts);
                    window.localStorage.setItem("Product", newProduct);
                    setIsHeartActive(true);
                    toast.success("Product added to wishlist.");
                } else {
                    setIsHeartActive(true);
                    toast.success("Product is already in your wishlist.");
                }
            }
            // Update cart count
            updateCartCount(setcartCount, setCartWishCount);
        }
    };


    useEffect(() => {
        setAllCartProduct(CartProduct)
        let price = 0
        for (let i = 0; i <= CartProduct.length - 1; i++) {
            console.log(CartProduct[i].price, "newlydata")
            price += CartProduct[i].price
        }
        console.log(price, "priceprice")
        let subTotal = price;
        let vats = subTotal * 0.15;
        let Total = subTotal + (subTotal * 0.15)
        setCartOrderSummary({
            subTotal: subTotal,
            discount: 0,
            shippingCost: 0,
            vat: vats,
            total: Total,
            afterDiscount: 0
        });
    }, []);

    const redirectToProductDetails = (subcategoryId, productId) => {
        if (localStorage.getItem('token')) {
            navigate({
                pathname: '/product-details',
                search: `?subcategoryId=${subcategoryId}&productId=${productId}`,
            });
        }
    };


    const proceedCheckout = () => {
        let token = window.localStorage.getItem('token')
        if (!token) {
            toast.warning("please login and proceed the checkout")
        } else {
            navigate('/Checkoutshipping')
        }

    }

    const applyCouponHanler = () => {
        window.localStorage.setItem('coupon', applyCoupon)
        fetch(`${appURL}/productOrder/applyCoupon`, {
            ...fetchConfig,
            method: 'POST',
            body: JSON.stringify({
                couponCode: applyCoupon,
            }),
        })
            .then(handleFetchErrors)
            .then((dataAllCartProduct) => {
                console.log(dataAllCartProduct, 'dataAllCartProductdataAllCartProduct');
                setmessage(dataAllCartProduct.data.message);
                if (dataAllCartProduct.status === 200) {
                    setmessage('Coupon Applied');
                    console.log(dataAllCartProduct.data.totalProductPrice, 'hello word');
                    setCartOrderSummary({
                        subTotal: dataAllCartProduct.data.totalProductPrice.subTotal,
                        discount: dataAllCartProduct.data.totalProductPrice.discount,
                        shippingCost: dataAllCartProduct.data.totalProductPrice.shippingCost,
                        vat: dataAllCartProduct.data.totalProductPrice.vat,
                        total: dataAllCartProduct.data.totalProductPrice.total,
                        afterDiscount: dataAllCartProduct.data.totalProductPrice.total
                    });
                    settotalProductPrice(dataAllCartProduct.data.totalProductPrice);
                }
                // console.log('---x----x----x----x----');
                // console.log(dataAllCartProduct.data.product, 'hhh');
                // setAllCartProduct(dataAllCartProduct.data.product);
                // setCartOrderSummary(dataAllCartProduct.data);
            })
            .catch(standardFetchHandlers.error);
    };


    console.log(cartOrderSummary, "totalProductPrice")

    useEffect(() => {
        if (tokenExists) {
            fetchAllCartProduct()
        }
    }, [tokenExists])
    const [productsLoaded, setProductsLoaded] = useState(false);


    return (
        <div className={style.main_wrapper}>
            <div className={style.shoping_cart}>
                <h1>Shopping Cart</h1>
                <p>{allCartProduct.length} item(s) in Cart</p>
            </div>

            <div className={style.shoping_cart_tabs}>
                <div className={style.shoping_cart_tabs_lists}>
                    <div>
                        <p>Product</p>
                    </div>
                    <div>
                        <p>Description</p>
                    </div>
                </div>
                <div className={style.shoping_cart_tabs_lists2}>
                    <div>
                        <p>Price</p>
                    </div>
                    <div>
                        <p>Quantity</p>
                    </div>
                    <div>
                        <p>Total</p>
                    </div>
                </div>
            </div>
            {loader && <Loader />}
            {
                allCartProduct.map((allproductByFilter) => {
                    return (

                        <div className={style.shoping_cart_items}>
                            <div className={style.shoping_cart_tabs_lists_filter}>
                                <div className={style.shoping_cart_item_filters}>
                                    {/* <img src="/assets/Rectangle 114 (1).png" alt="" /> */}
                                    <img src={allproductByFilter.thumbnail ? `${IMG_URL}${allproductByFilter.thumbnail[0]}` : `/assets/images/break.png`} alt=""
                                        onClick={() => redirectToProductDetails(allproductByFilter.id, allproductByFilter.productId)}
                                    />
                                    <h5 onClick={() => redirectToProductDetails(allproductByFilter.id, allproductByFilter.productId)}>{allproductByFilter.title}</h5>
                                </div>
                                <div className={style.shoping_cart_description}>
                                    <h4>{allproductByFilter.description}</h4>
                                    {/* <p>Rear brake pads</p> */}
                                </div>
                            </div>

                            <div className={style.shoping_cart_tabs_lists_product} >
                                <div className={style.shoping_cartprice_mark}>
                                    <p>{allproductByFilter.productPrice}<span> SAR</span></p>
                                </div>
                                <div>

                                    <button style={{ backgroundColor: '#FFFFFF' }} className={style.increamnet_decreament_btn}>
                                        {/* <span
                                    onClick={() => decNum(allproductByFilter, allproductByFilter.quantity)}
                                    disabled={allproductByFilter.quantity === 1}
                                    className={allproductByFilter.quantity === 1 ? style.disabled : ''}
                                > */}
                                        <span
                                            onClick={() => {
                                                console.log("Current Quantity:", quantity); // Log current quantity
                                                // if (quantity > 1) {
                                                decNum(allproductByFilter, allproductByFilter.quantity); // Only call decNum if quantity is more than 1
                                                // }
                                            }}
                                            className={quantity === 1 ? style.disabled : ''}
                                            style={{ pointerEvents: quantity === 1 ? 'none' : 'auto' }}
                                        >
                                            -
                                        </span>

                                        {console.log('dddddddddddddddd', allproductByFilter.quantity)}
                                        <span>{allproductByFilter.quantity}</span>
                                        <span onClick={() => incNum(allproductByFilter, allproductByFilter.quantity)}>+</span>
                                    </button>


                                </div>

                                <div className={style.orange_text}>
                                    <p>{allproductByFilter.price.toFixed(2)}<span>SAR</span></p>
                                </div>
                                <div className={style.wishlist_icons}>
                                    <div className={style.cart_icon}>
                                        {/* {
                                            allproductByFilter.isFavorite === 0 ? (
                                                
                                                <i onClick={(event) => updateFavoriteProduct(event, allproductByFilter.id)} className="fa-regular fa-heart"></i>
                                            ) : (
                                                <i onClick={(event) => updateFavoriteProduct(event, allproductByFilter.id)} className="fa-solid fa-heart" style={{ color: '#c50e0e' }}></i>
                                            )
                                        } */}
                                              <AddToWishlistButton
                                productDetails={allproductByFilter}
                                setcartCount={allproductByFilter.setcartCount}
                                cartWishCount={allproductByFilter.cartWishCount}
                                setCartWishCount={allproductByFilter.setCartWishCount}
                                productId={allproductByFilter.productId} // Pass productId as a prop
                            />

                                        {/* <img src="/assets/heart.png" alt="" /> */}
                                        <i onClick={() => { removeCart(allproductByFilter.id) }} className="fa-solid fa-xmark"></i>
                                    </div>
                                </div>

                            </div>

                        </div>
                    )
                })
            }
            {allCartProduct.length > 0 ? (
                <div className={style.shoping_cart_list_products}>
                    <div className={style.coupan_code_box} >
                        <div >
                            <h2>Coupon Code</h2>
                            <p>Enter your coupon code if you have one.</p>
                        </div>
                        <div className={style.form_box}>
                            <input type="text" placeholder="Enter your coupon code" onChange={(event) => { setapplyCoupon(event.target.value) }} readOnly={message === "Coupon Applied"} />
                            <button onClick={applyCouponHanler} disabled={message === "Coupon Applied"}>
                                {message === "Coupon Applied" ? "Applied" : "Apply"}
                            </button>

                        </div>

                        {/* <div>
                            {message && (
                                <span style={{ color: message === "Invalid coupon code" ? 'red' : 'green' }}>
                                    {message}
                                </span>
                            )}
                            <span>{message === "Coupon Applied" ? <span>You saved SAR {totalProductPrice.discount.toFixed(2)} with this coupon code</span> : null}</span>

                        </div> */}
                        <div>
                            {message && (
                                <>
                                    <span style={{ color: message === "Invalid coupon code" ? 'red' : 'green' }}>
                                        {message}
                                    </span>
                                    <br />
                                    {message === "Coupon Applied" && (
                                        <div
                                            style={{
                                                backgroundColor: '#e0ffff',
                                                color: 'black',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid green',
                                                marginTop: '10px',
                                                
                                            }}
                                        >
                                            You saved SAR <strong>{totalProductPrice.discount.toFixed(2)}</strong> with this coupon code
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                    </div>

                    <div className={style.order_summary_section}>
                        <div>
                            <h2 className={style.order_summary_heading}>Order Summary</h2>
                        </div>
                        <div className={style.order_summary}>
                            <div className={style.order_summary_lists}>
                                <p>Sub Total:</p>
                                {/* <p>Discount</p>
                        <p>Estimated Shipping Cost:</p> */}
                                <p>VAT:</p>
                                <h2>Total:</h2>
                            </div>
                            <div className={style.order_summary_lists1}>
                                <p>{cartOrderSummary.subTotal} SAR</p>
                                {/* <p>{!cartOrderSummary.discount?0:`(${cartOrderSummary.subTotal}-${cartOrderSummary.discount})=${cartOrderSummary.afterDiscount.toFixed(2)}`} SAR</p>
                        <p>{cartOrderSummary.shippingCost} SAR</p> */}
                                <p>{cartOrderSummary.vat.toFixed(2)} SAR</p>
                                <h2>{cartOrderSummary.total.toFixed(2)} SAR</h2>
                            </div>
                        </div>
                        <button onClick={proceedCheckout}>Proceed to checkout</button>
                    </div>
                </div>
            ) : (
                <div style={{ textAlign: "center" }}>
                    <img src={NodDataImg} width="50%" alt="" />
                </div>
            )}
        </div>
    )
}
