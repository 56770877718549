import React from 'react';

function RefundModal({ isOpen, onClose, onConfirm, refundAmount }) {
    return (
        <div style={{
            display: isOpen ? 'flex' : 'none',
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0,0.5)',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                background: 'white',
                padding: '30px',
                width: '50%',
                maxWidth: '600px', // Ensures the modal is not too wide on larger screens
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                textAlign: 'center', // Centers the text
            }}>
                <h4>Refundable Amount</h4>
                <p style={{ marginTop: '20px', fontSize: '16px' }}>
                    If your request gets approved for the return of the product, you will receive a refundable amount of <strong>{refundAmount}</strong> SAR. 
                </p>
                <div style={{
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>
                    <button onClick={onConfirm} style={{
                        padding: '10px 20px',
                        cursor: 'pointer',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }}>
                        Confirm
                    </button>
                    <button onClick={onClose} style={{
                        padding: '10px 20px',
                        cursor: 'pointer',
                        backgroundColor: '#f44336',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RefundModal;
