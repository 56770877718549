import React from 'react';

import Input from '../input/Input';
import { Link } from 'react-router-dom';
import SocialAuthBtn from '../auth/social-auth-btn/SocialAuthBtn';
import { useTranslation } from "react-i18next";
import style from './footer.module.css';

export default function Footer() {
    const { t } = useTranslation();
    let tokenexits = window.localStorage.getItem("token")

    return (

        <footer className={style.footer_main_wrapper}>

            <div className={style.footer_wrapper}>

                <section className={style.contactus_section}>
                    <h3 className={style.conatctus_heading}>{t('footerConatctUsHeading')}</h3>

                    <div className={style.address_text}>
                        <aside>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#f86b1f" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"><circle cx="12" cy="10" r="3" /><path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" /></svg>

                        </aside>

                        <div className={style.arabic_address_text}>
                            <p>{t('footerConatctUsLocationTextHeading')}</p>
                            <span>{t('footerConatctUsLocationText')}</span>
                        </div>
                    </div>

                    <div className={style.phone_number}>

                        <aside>
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 10.315V12.967C14.2501 13.1569 14.1781 13.3397 14.0487 13.4786C13.9192 13.6176 13.7419 13.7022 13.5525 13.7155C13.2247 13.738 12.957 13.75 12.75 13.75C6.12225 13.75 0.75 8.37775 0.75 1.75C0.75 1.543 0.76125 1.27525 0.7845 0.9475C0.797792 0.758083 0.882436 0.580758 1.02135 0.451307C1.16027 0.321855 1.34312 0.249914 1.533 0.25H4.185C4.27803 0.249906 4.36777 0.284394 4.4368 0.346765C4.50582 0.409136 4.5492 0.494937 4.5585 0.5875C4.57575 0.76 4.5915 0.89725 4.6065 1.0015C4.75555 2.04169 5.061 3.05337 5.5125 4.00225C5.58375 4.15225 5.53725 4.3315 5.40225 4.4275L3.78375 5.584C4.77334 7.88984 6.61091 9.72741 8.91675 10.717L10.0717 9.1015C10.119 9.0355 10.1878 8.98816 10.2664 8.96774C10.3449 8.94732 10.4281 8.95511 10.5015 8.98975C11.4503 9.4404 12.4617 9.74509 13.5015 9.8935C13.6057 9.9085 13.743 9.925 13.914 9.9415C14.0064 9.95098 14.092 9.99443 14.1543 10.0634C14.2165 10.1324 14.2509 10.2221 14.2507 10.315H14.25Z" fill="#F86B1F" />
                            </svg>
                        </aside>

                        <div className={style.arabic_address_text}>
                            <p>{t('footerConatctUsCallTextHeading')}</p>
                            <span>{t('footerConatctUsCallText')}</span>
                        </div>

                    </div>

                    <div className={style.email_address}>
                        <aside>

                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 0.25H14.25C14.4489 0.25 14.6397 0.329018 14.7803 0.46967C14.921 0.610322 15 0.801088 15 1V13C15 13.1989 14.921 13.3897 14.7803 13.5303C14.6397 13.671 14.4489 13.75 14.25 13.75H0.75C0.551088 13.75 0.360322 13.671 0.21967 13.5303C0.0790176 13.3897 0 13.1989 0 13V1C0 0.801088 0.0790176 0.610322 0.21967 0.46967C0.360322 0.329018 0.551088 0.25 0.75 0.25ZM7.545 6.76225L2.736 2.6785L1.76475 3.8215L7.55475 8.73775L13.2405 3.81775L12.2595 2.683L7.54575 6.76225H7.545Z" fill="#F86B1F" />
                            </svg>

                        </aside>
                        <div className={style.arabic_address_text}>
                            <p>{t('footerConatctUsEmailTextHeading')}</p>
                            <span>{t('noreply.anycarpart@gulfmotors.sa')}</span>
                        </div>

                    </div>

                </section>

                <div className={style.services_section}>

                    <h3 className={style.service_section_heading}>{t('footerCustomerServiceHeading')}</h3>
                    <ul>
                        <li><Link to="/help-center1">{t('footerCustomerServiceHelpCenter')}</Link></li>
                        {tokenexits ? (
                            <>
                                <li>
                                    <Link to="/accounts">{t('footerCustomerServiceMyAccount')}</Link>
                                </li>
                                <li>
                                    <Link to="/track-order">{t('footerCustomerServiceTrackMyOrder')}</Link>
                                </li>
                            </>
                        ) : null}


                        <li><Link to="/Privacy">{t('footerCustomerServiceReturnPloicy')}</Link> </li>
                        <li><Link to="/Pricematch">{t('footerCustomerServiceProiceMatch')}</Link> </li>
                    </ul>
                </div>

                <div className={style.information_section}>
                    <h3 className={style.information_section_heading}>{t('footerInformation')}</h3>
                    <ul>
                        <li><Link to="/aboutus">{t('footerAboutUs')}</Link></li>
                        <li><Link to="/news-guide">{t('footerArticles')}</Link></li>
                        <li><Link to="/customers-reviews">{t('footerReviews')}</Link></li>
                        {tokenexits ? (
                            <>
                                  <li> <Link to="/Couponcode">{t('footerCodes')}</Link> </li>
                            </>
                        ) : null}
                      
                        <li> <Link to="/giftcard">{t('footerCards')}</Link>  </li>
                        <li> <Link to="/contact-us">{t('footerContact')}</Link></li>
                    </ul>
                </div>

                <div className={style.subscribe_section}>

                    <h3 className={style.subscribe_section_heading}>{t('footerSubscribe')}</h3>

                    <p>{t('footerCaptionforSubscription')}</p>

                    <form id={style.subscription_form}>
                        <Input
                            type='email'
                            placeholder={t('footerEmailPlaceholder')}
                            className={style.subscription_email_input}
                        />
                        <button>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.672 6.99992L7.308 1.63592L8.722 0.221924L16.5 7.99992L8.722 15.7779L7.308 14.3639L12.672 8.99992H0.5V6.99992H12.672Z" fill="white" />
                            </svg>
                        </button>

                    </form>

                    <SocialAuthBtn className={style.hghsdf} source="footer" />

                </div>

            </div>

            <p className={style.copy_right_text}>{t('footerCopyright')}<span>{t('footerGlobe')}</span></p>

        </footer>
    )
}
