import React from 'react';
import style from './pagination.module.css';

const CustomPagination = ({ pagination, text }) => {
    const [currentPage, setCurrentPage] = React.useState(1); // Track current page

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= pagination) {
            setCurrentPage(pageNumber);
        }
    };

    const renderPages = () => {
        const pages = [];
        for (let i = 1; i <= pagination; i++) {
            pages.push(
                <button
                    key={i}
                    style={{
                        border: '1px solid #ccc',
                        padding: '5px 10px',
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                        color: '#212F63',
                        marginRight: '5px',
                        ...(currentPage === i ? { backgroundColor: '#f0f0f0' } : {}),
                    }}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    return (
        <section className={style.pagination_view_all_wrapper}>
            {pagination && (
                <div className="pagination-buttons" style={{ marginRight: '10px' }}>
                    {renderPages()}
                    <button
                        style={{
                            border: '1px solid #ccc',
                            padding: '5px 10px',
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            color: '#212F63',
                          

                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#212F63"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"

                        >
                            <path d="M5 12h13M12 5l7 7-7 7" />
                        </svg>
                    </button>

                </div>
            )}
            {text && 
            
               <span style={{ color: '#212F63', padding: '5px', marginRight: '5px' }}>
              {text}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#212F63"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style={{ marginTop: '2px' }} // Inline style for margin-top
              >
                <path d="M5 12h13M12 5l7 7-7 7" />
              </svg>
            </span>
            
            }
            </section>
    );
};

export default CustomPagination;
