import React, { useEffect } from 'react';

const VisaCheckout = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sandbox-assets.secure.checkout.visa.com/checkout-widget/resources/js/integration/v1/sdk.js';
    script.async = true;

    script.onload = () => {
      window.V.init({
        apiKey: '#API_KEY#',
        externalProfileId: '#PROFILE_NAME#',
        settings: {
          locale: 'en_AE',
          countryCode: 'AE',
          review: {
            message: 'Merchant defined message',
            buttonAction: 'Continue',
          },
          threeDSSetup: {
            threeDSActive: 'false',
          },
        },
        paymentRequest: {
          currencyCode: 'USD',
          subtotal: '10.00',
        },
      });

      window.V.on('payment.success', function (payment) {
        console.log(JSON.stringify(payment)); // response when received success operation
      });

      window.V.on('payment.cancel', function (payment) {
        console.log(JSON.stringify(payment)); // response when cancel operation
      });

      window.V.on('payment.error', function (payment, error) {
        console.log(JSON.stringify(payment)); // response when received error operation
        console.log(error);
      });
    };

    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <img
        alt="Visa Checkout"
        className="v-button"
        role="button"
        src="https://sandbox.secure.checkout.visa.com/wallet-services-web/xo/button.png?cardBrands=VISA,MASTERCARD,DISCOVER,AMEX"
      />
    </div>
  );
};

export default VisaCheckout;
