module.exports = {
    emailAddressRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    indianMobileNumberRegex: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
    passwordRegex: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,30}$/,
    pincode: {
        india: /^[1-9][0-9]{5}$/i
    },

    govtID: {
        india: {
            gstin: /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-8]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/i,
            pan: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/i,
            aadhar: /^[2-9]{1}[0-9]{3}\s?[0-9]{4}\s?[0-9]{4}$/i
        }
    },

    color: {
        all: /(#([\da-f]{6})|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/gi,
        hex: /^#(?:[0-9a-fA-F]{6}){1,2}$/i,
        rgbOrRgba: /((rgb)a\((\d{1,3}%?,\s?){3}(1|0|0?\.\d+)\)|(rgb)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/gi,
        hslOrHsla: /((hsl)a\((\d{1,3}%?,\s?){3}(1|0|0?\.\d+)\)|(hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/gi
    },

    url: {
        http: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i
    }
};
