import React from 'react';
import { Link } from "react-router-dom";
import style from './pagination.module.css';
import { useTranslation } from "react-i18next";

export default function Pagination({ paginationPage=null, productPage=null,isViewAllBtn = true, viewAllBtnText = `See all products`, viewAllBtnLink = ``, classNameForLoadMoreText =``, classname = ``, svgIcon =<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#212F63" stroke-width="2" stroke-linecap="round" strokeLinejoin="round">
                        <path d="M5 12h13M12 5l7 7-7 7" />
                    </svg> }) {
const { t } = useTranslation();
console.log('22222222222222222');
console.log(productPage);

for (var paginationPage=1; paginationPage < 4; paginationPage++) {
    console.log(paginationPage);
}

    return ( 

        <section className={style.pagination_view_all_wrapper}>

            <aside className={`${style.pagination_wrapper} ${classname}` } >
                
                {
                    (() => {
                        const rows = [];
                        for (var i=1; i < paginationPage; i++) {
                            console.log(i);
                            rows.push(i);
                            // return(

                            //     <span> {i}</span>
                            // )
                        }
                        console.log(rows);
                        
                        rows.map((row) => {
                                    
                            return (
                                <span>{row[0]}</span>
                            )
                        })
                    })()
                }

                {/* <span>{t('Pagnation2')}</span>
                <span>{t('Pagnation3')}</span>
                <span>{t('Pagnation4')}</span>
                <span>{t('Pagnation5')}</span> */}
                <span >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#212F63" stroke-width="2" stroke-linecap="round" strokeLinejoin="round">
                        <path d="M5 12h13M12 5l7 7-7 7" />
                    </svg>
                    
                </span>
            </aside>

            {
                isViewAllBtn
                ?
                    <Link to={viewAllBtnLink}>
                        <span className={`${style.view_all_action_btn} ${classNameForLoadMoreText}`}>
                        {t('HomeProductSeacrhProduct')}
                            {svgIcon}
                            
                        </span>
                </Link>
                    
                :
                    ``
            }

        </section>

    )
}
