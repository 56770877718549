import React from 'react';
import Input from '../../components/input/Input';
import HeroImage from '../../components/hero-image/HeroImage';
import Image from '../../assets/images/accountPage.png'
import style from './account.module.css'
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import { API_BASE_URL, IMG_URL } from '../../config/config'
import { toast } from 'react-toastify';

export default function Account() {
  // const appURL = `https://admin-anycarpart.go-demo.com/api/v1`;
  const appURL = `http://localhost:3003/api/v1`;
  const imageURL = `https://admin-anycarpart.go-demo.com`;
  const fetchConfig = getFetchConfig();
  const { t } = useTranslation();
  const [productOrder, setProductOrder] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [userProfileInfo, setUserProfileInfo] = useState(``);
  const [userBillingAddress, setUserBillingAddress] = useState(``);
  const [userAllProfile, setUserAllProfile] = useState([])
  const [address, setAddress] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [emailAddress, setEmailAddress] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingCountry, setBillingCountry] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingZIP, setBillingZIP] = useState('');
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  // const token = window.localStorage.getItem('token');

  useEffect(() => {
    const storedToken = window.localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);

    } else {
      setLoading(false);
    }
  }, []);

  const getProductOrder = (productById) => {
    fetch(`${API_BASE_URL}/productOrder`, {
      ...fetchConfig,
      method: 'POST',
      body: JSON.stringify({
        productId: productById,
      }),
    })
      .then(handleFetchErrors)
      .then((dataplace) => {
        console.log(dataplace.data.orders, 'getproductpi');
        const order = dataplace.data.orders;
        const billingAddressData = order[0].billingAddress || {};
        setBillingAddress(billingAddressData.address || '');
        setBillingCountry(billingAddressData.country || '');
        setBillingState(billingAddressData.state || '');
        setBillingCity(billingAddressData.city || '');
        setBillingZIP(billingAddressData.zipCode || '');
        setUserBillingAddress(billingAddressData)
      })
      .catch(standardFetchHandlers.error);
  };

  const fetchUserAllProfile = () => {

    fetch(`${API_BASE_URL}/profile`, {
      ...fetchConfig,
      method: `GET`,
    })
      .then(handleFetchErrors)
      .then((dataUserAllProfile) => {
        console.log(dataUserAllProfile.data, "helll");
        setUserAllProfile(dataUserAllProfile.data.user);
        setUserProfileInfo(dataUserAllProfile.data.user.userInfo);
        setIsVerified(dataUserAllProfile?.data?.user?.userInfo?.isVerified);
        setEmailAddress(dataUserAllProfile.data.user.userInfo.email_address); // Set email address state
        console.log('userinfoooooooooooooooooooooo,', dataUserAllProfile.data.user.userInfo)
      })
      .catch(standardFetchHandlers.error);
  };
  const fetchUserBillingAddress = () => {
    fetch(`${API_BASE_URL}/profile/fetch-billing-address`, {
      ...fetchConfig,
      method: `GET`,
    })
      .then(handleFetchErrors)
      .then((response) => {
        console.log('reppppppppppppppppp', response);
        const billingAddressData = response.userAddress;
        if (billingAddressData) {
          setBillingAddress(billingAddressData.address || '');
          setBillingCountry(billingAddressData.country || '');
          setBillingState(billingAddressData.state || '');
          setBillingCity(billingAddressData.city || '');
          setBillingZIP(billingAddressData.zipCode || '');
          setUserBillingAddress(billingAddressData);
        } else {
          // Handle case when userAddress is not found in response
          console.error('Billing address data not found in response');
        }
      })
      .catch(standardFetchHandlers.error);
  };

  const resendVerificationEmail = () => {
    fetch(`${API_BASE_URL}/account/resend-verification-email`, {
      ...fetchConfig,
      method: 'POST',
      body: JSON.stringify({ email_address: emailAddress }), // Use email address state
    })
      .then(handleFetchErrors)
      .then(() => {
        toast.success('Verification email sent successfully!');
      })
      .catch((error) => {
        standardFetchHandlers.error(error);
        toast.error('Failed to send verification email.');
      });
  };
  const handleProfileUpdate = (event) => {
    event.preventDefault();

    fetch(`${API_BASE_URL}/profile/update`, {
      ...fetchConfig,
      method: 'POST',
      body: JSON.stringify(userProfileInfo),
    })
      .then(handleFetchErrors)
      .then((response) => {
        toast.success('Profile updated successfully!');
      })
      .catch((error) => {
        standardFetchHandlers.error(error);
        toast.error('Failed to update profile.');
      });
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserProfileInfo(prevState => ({ ...prevState, [name]: value }));

  };
  const updateBillingAddress = (event) => {
    event.preventDefault();
    const updatedAddress = {
      address: billingAddress,
      country: billingCountry,
      state: billingState,
      city: billingCity,
      zipCode: billingZIP
    };

    fetch(`${API_BASE_URL}/profile/update-billingAddress`, {
      ...fetchConfig,
      method: 'POST',
      body: JSON.stringify(updatedAddress), // Sending updatedAddress instead of userBillingAddress
    })
      .then(handleFetchErrors)
      .then((response) => {
        toast.success('Billing address updated successfully!');
        // Optionally, update userBillingAddress state if needed
        setUserBillingAddress(updatedAddress);
      })
      .catch((error) => {
        standardFetchHandlers.error(error);
        toast.error('Failed to update billing address.');
      });
  };

  const handleBillingInput = (event) => {
    const { name, value } = event.target;
    // Update the corresponding state variable directly
    switch (name) {
      case "address":
        setBillingAddress(value);
        break;
      case "country":
        setBillingCountry(value);
        break;
      case "state":
        setBillingState(value);
        break;
      case "city":
        setBillingCity(value);
        break;
      case "zipCode":
        setBillingZIP(value);
        break;
      default:
        break;
    }
  };

  const handleSignouthandler = () => {
    window.localStorage.removeItem('token');
    navigate('/');
  };



  useEffect(() => {
    getProductOrder();
    fetchUserAllProfile();
    fetchUserBillingAddress();
  }, []);
  //   if (loading) {
  //     return <div>Loading...</div>;
  // }

  if (!token) {
    navigate('/');
    return null;
  }

  if (!isVerified) {
    return (
      <div style={{ textAlign: 'center', margin: '40px auto', maxWidth: '600px' }}>
        <h1 style={{ fontSize: '32px', marginBottom: '20px' }}>Email Verification Required</h1>
        <p style={{ fontSize: '16px', marginBottom: '30px' }}>
          Please verify your email address to access your account features.
          Click on the button below to resend the verification email.
        </p>
        <button
          style={{
            backgroundColor: '#212F63',
            color: '#fff',
            padding: '12px 24px',
            border: 'none',
            borderRadius: '2px',
            cursor: 'pointer',
            fontSize: '16px',
            marginBottom: '30px',
          }}
          onClick={resendVerificationEmail}
        >
          Resend Verification Email
        </button>
        <p style={{ fontSize: '16px' }}>
          Click on the resend button and you will receive an email at <strong>{emailAddress}</strong>.
        </p>
      </div>
    );
  }
  return (
    <main className={style.main_wrapper}>
      {token && isVerified ? (
        <>
          <HeroImage
            Heading={t('AccountsName')}
            HeroImage={Image}
          />
          <section className={style.account_detail_section_wrapper}>
            <div className={style.account_order_lists}>

              <ul>
                <Link to="/orders">
                  <li className={style.clickable_text}>
                    <a onclick="toggleActive(event)" href="#">My Orders</a>
                  </li>
                </Link>
                <li>

                </li>
                <Link to="/my-special-orders">
                  <li className={style.clickable_text}>
                    <a onclick="toggleActive(event)" href="#">Special Orders</a>
                  </li>
                </Link>
                <Link to="/accounts">
                  <li className={style.clickable_text}>
                    <a onclick="toggleActive(event)" href="#">My details</a>
                  </li>
                </Link>

                <Link to="/Changepassword">
                  <li className={style.clickable_text}>
                    <a onclick="toggleActive(event)" href="#">Change password</a>
                  </li>
                </Link>

                <li className={style.clickable_text}>
                  <a onClick={handleSignouthandler}>Sign out</a>
                </li>
              </ul>
            </div>
            <aside>
              <form onSubmit={handleProfileUpdate}>
                <div className={style.user_detail_section}>
                  <h1>{t('AccountsMyDeailTitle')}</h1>
                  <div className={style.user_detail_input_section}>
                    <div>
                      <label className={style.input_label}>{t('AccountsMyDeailLabelFirstName')}</label>

                      <input
                        className={style.input_field}
                        type='text'
                        placeholder={t('AccountsMyDeailFirstName')}
                        name="first_name"
                        value={userProfileInfo.first_name || ''}
                        onChange={handleInputChange}


                      />
                    </div>
                    <div>
                      <label className={style.input_label}>{t('AccountsMyDeailLabelLastName')}</label>

                      <input
                        type='text'
                        className={style.input_field}
                        placeholder={t('AccountsMyDeailLastName')}
                        // label={t('AccountsMyDeailLabelLastName')}
                        name="last_name"
                        value={userProfileInfo.last_name || ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label className={style.input_label}>{t('AccountsMyDeailLabelEmail')}</label>

                      <input
                        type='text'
                        className={style.input_field}
                        placeholder={t('AccountsMyDeailEmail')}
                        // label={t('AccountsMyDeailLabelEmail')}
                        name="email_address"
                        value={userProfileInfo.email_address || ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label className={style.input_label}>{t('AccountsMyDeailLabelMobile')}</label>

                      <input
                        type='text'
                        className={style.input_field}
                        placeholder={t('AccountsMyDeailMobile')}
                        // label={t('AccountsMyDeailLabelMobile')}
                        name="phone_number"
                        value={userProfileInfo.phone_number || ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    {userProfileInfo.companyName && (
                      <div>
                        <label className={style.input_label}>Company Name</label>
                        <input
                          type='text'
                          className={style.input_field}
                          placeholder='Company Name'
                          name="companyName"
                          value={userProfileInfo.companyName || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    )}

                  </div>
                </div>
                <button className='btn1' type="submit">
                  {t('updateProfilebtn')}
                </button>
              </form>
              <form onSubmit={updateBillingAddress}>
                <div className={style.billing_address_section}>
                  <h1>{t('BillingTitle')}</h1>
                  <div className={style.billing_address_input_section}>
                    <label className={style.input_label}>{t('BillingAddress')}</label>

                    <textarea
                      className={style.input_field}
                      type='text'
                      placeholder={t('BillingAddress')}
                      id='span-2'
                      value={billingAddress}
                      onChange={(e) => setBillingAddress(e.target.value)} // Updated to directly setBillingAddress
                    />
                    <div>
                      <label className={style.input_label}>{t('BillingCountry')}</label>

                      <input
                        className={style.input_field}

                        type='text'
                        placeholder={t('BillingCountry')}
                        label={t('BillingCountry')}
                        id='span-2'
                        value={billingCountry}
                        onChange={(e) => setBillingCountry(e.target.value)}
                      />
                    </div>
                    <div >

                      <label className={style.input_label}>{t('BillingState')}</label>

                      <input
                        className={style.input_field}

                        type='text'
                        placeholder={t('BillingState')}
                        // label={t('BillingState')}
                        value={billingState}
                        id='span-2'
                        onChange={(e) => setBillingState(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className={style.input_label}>{t('BillingCity')}</label>

                      <input
                        type='text'
                        className={style.input_field}
                        placeholder={t('BillingCity')}
                        // label={t('BillingCity')}
                        value={billingCity}
                        id='pet-select'
                        onChange={(e) => setBillingCity(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className={style.input_label}>{t('BillingZIP')}</label>

                      <input
                        type='text'
                        className={style.input_field}
                        placeholder={t('BillingZIP')}
                        // label={t('BillingZIP')}
                        id='span-2'
                        name="billingAddress" // Add name attribute
                        value={billingZIP}
                        onChange={(e) => setBillingZIP(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

              </form>
              <form onSubmit={updateBillingAddress}>
                <div className={style.shipping_address_section}>
                  <div className={style.shipping_address_text}>
                    <h1>{t('ShippingTitle')}</h1>
                    <p>{t('CopyfromBilling')}</p>
                  </div>
                  <div className={style.shipping_address_input_section}>

                    <Input
                      type='text'
                      placeholder={t('BillingAddress')}
                      label={t('BillingAddress')}
                      id='span-2'
                      value={billingAddress}
                      onChange={(e) => setBillingAddress(e.target.value)}
                    />
                    <Input
                      type='text'
                      placeholder={t('BillingCountry')}
                      label={t('BillingCountry')}
                      id='span-2'
                      value={billingCountry}
                      onChange={(e) => setBillingCountry(e.target.value)}
                    />
                    <Input
                      type='text'
                      placeholder={t('BillingState')}
                      label={t('BillingState')}
                      value={billingState}
                      id='span-2'
                      onChange={(e) => setBillingState(e.target.value)}
                    />
                    <Input
                      type='text'
                      placeholder={t('BillingCity')}
                      label={t('BillingCity')}
                      value={billingCity}
                      id='pet-select'
                      onChange={(e) => setBillingCity(e.target.value)}
                    />
                    <Input
                      type='text'
                      placeholder={t('BillingZIP')}
                      label={t('BillingZIP')}
                      value={billingZIP}
                      onChange={(e) => setBillingZIP(e.target.value)}
                    />
                  </div>
                  {/* <button className='btn1' onClick={updateBillingAddress}>
      {t('updateProfilebtn')}
    </button> */}

                </div>
                <button className='btn1' type="submit1">
                  Update Billing Address
                </button>
              </form>

            </aside>
          </section>
        </>) : (

        <>
          {navigate('/')}
        </>
      )}
    </main>
  )
}
