import React from 'react';
import style from './services.module.css'
import ProductHeroImage from '../../assets/images/home/ttt.png';
import { useTranslation } from "react-i18next";
export default function ServiceCard({ imageSrc, textContent }) {

    const { t } = useTranslation();
    return (


        <section className={style.image_container}>
            <img src={imageSrc} alt="" srcset="" />

            <div id={style.card_heading_div} className={`${style.card_heading} ${style.arabic_card_heading}`}>
                <p>{textContent}</p>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.17266 7.00001L0.222656 2.05001L1.63666 0.636015L8.00066 7.00001L1.63666 13.364L0.222656 11.95L5.17266 7.00001Z" fill="white" />
                </svg>
            </div>
        </section>
    )
}
