import React, { useState, useEffect } from 'react';
import style from '../my-order/myaccount.module.css';
import { useTranslation } from "react-i18next";
import HeroImage from '../../components/hero-image/HeroImage';
import Image from '../../assets/images/accountPage.png';
import { Link } from 'react-router-dom';
import {API_BASE_URL} from '../../config/config';
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';

export default function MySpecialOrders() {
    const [specialOrders, setSpecialOrders] = useState([]);
    const [expandedOrderId, setExpandedOrderId] = useState(null);
    const { t } = useTranslation();
    const fetchConfig = getFetchConfig();

    useEffect(() => {
        const getProductOrder = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/specialOrder`, {
                    ...fetchConfig,
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch special orders');
                }

                const data = await response.json();
                setSpecialOrders(data.specialOrders);
            } catch (error) {
                console.error('Error fetching special orders:', error);
            }
        };

        getProductOrder();
    }, []);

    const handleOrderClick = (orderId) => {
        setExpandedOrderId(prevId => prevId === orderId ? null : orderId);
    };

    const isOrderExpanded = (orderId) => expandedOrderId === orderId;

    return (
        <div className={style.main_wrapper1}>
            <HeroImage
                Heading={t('AccountsName')}
                HeroImage={Image}
            />
            <div className={style.main_wrapper}>
                <div className={style.order_lists}>
                    <div className={style.account_order_lists}>
                        <ul>
                            <Link to="/orders">
                                <li className={style.clickable_text}>
                                    <a href="#">My Orders</a>
                                </li>
                            </Link>
                            <li>
                                <Link to="/my-special-orders">
                                    <li className={style.clickable_text}>
                                        <a href="#">Special Orders</a>
                                    </li>
                                </Link>
                            </li>
                            <Link to="/accounts">
                                <li className={style.clickable_text}>
                                    <a href="#">My details</a>
                                </li>
                            </Link>
                            <Link to="/Changepassword">
                                <li className={style.clickable_text}>
                                    <a href="#">Change password</a>
                                </li>
                            </Link>
                            <li className={style.clickable_text}>
                                <a href="#">Sign out</a>
                            </li>
                        </ul>
                    </div>
                    <div className={style.account_order_accordion_lists}>
                        <h1>My Special Orders</h1>
                        <div className={style.account_order_accordion_list_bar}>
                            {specialOrders.length > 0 ?
                                specialOrders.map((order, index) => (
                                    <div key={index} style={styles.orderContainer}>
                                        <div className={style.specialOrderItem} onClick={() => handleOrderClick(order._id)}>
                                            <div style={styles.orderDetails}>
                                            <div className={style.orderHeader}>
                                                <p style={styles.orderId}>Order {index + 1}</p>
                                                <p style={styles.orderId}>Order ID: {order._id}</p>
                                            </div>
                                            {/* {isOrderExpanded(order._id) && ( */}
                                                <div style={styles.orderDetailRow}>
                                                    <p style={styles.label}><strong>Name:</strong></p>
                                                    <p style={styles.value}>{order.firstname} {order.lastname}</p>
                                                </div>
                                                <div style={styles.orderDetailRow}>
                                                    <p style={styles.label}><strong>Product Name:</strong></p>
                                                    <p style={styles.value}>{order.productName}</p>
                                                </div>
                                                <div style={styles.orderDetailRow}>
                                                    <p style={styles.label}><strong>Payment Type:</strong></p>
                                                    <p style={styles.value}>{order.paymentType}</p>
                                                </div>
                                                <div style={styles.orderDetailRow}>
                                                    <p style={styles.label}><strong>VIN Number:</strong></p>
                                                    <p style={styles.value}>{order.vinNumber}</p>
                                                </div>
                                                <div style={styles.orderDetailRow}>
                                                    <p style={styles.label}><strong>Order Placed At:</strong></p>
                                                    <p style={styles.value}>{new Date(order.createdAt).toLocaleString()}</p>
                                                </div>
                                         
                                            </div>
                                            {/* )} */}
                                        </div>
                                    </div>
                                )) : <div style={{ textAlign: "center", paddingTop: "30px" }}>
                                    <p style={{ alignSelf: "center", marginTop: "30px" }}>No Data Found</p>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const styles = {
    orderContainer: {
        // marginBottom: '20px', // Add margin between each order
        // border: '1px solid #ccc', // Add border around each order
        // borderRadius: '8px', // Add border radius for rounded corners
        // padding: '20px', // Add padding to the order container
        // backgroundColor: '#f9f9f9', // Light background color
    },
    orderId: {
        fontSize: '1rem', // Decreased font size for orderId
        fontWeight: 'bold',
        color: '#333',
        margin: '0',
    },
    orderDetails: {
        marginLeft: '20px',
        marginTop: '10px',
        padding: '20px', // Increased padding for better spacing
        borderLeft: '2px solid #333',
        backgroundColor: '#f5f5f5', // Background color for order details
        borderRadius: '8px', // Adjusted border radius
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Added boxShadow for depth
    },
    orderDetailRow: {
        display: 'flex',
        marginBottom: '10px',
    },
    label: {
        fontWeight: 'bold',
        marginRight: '10px',
    },
    value: {
        marginBottom: '0',
    },
};

