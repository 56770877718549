import React from 'react';
import style from "./searchProduct.module.css"
import Input from '../input/Input';
import { useTranslation } from "react-i18next";
export default function SearchProduct() {
    const { t } = useTranslation();
    return (
        <div className={style.main_wrapper}>
            <h1 className={style.heading}>Search Product</h1>
            <Input type='text' placeholder='search parts' />
            <Input type='text' placeholder='find parts by VIN'   />
            <Input type='select' placeholder='find parts by VIN' />
            <button style={{ width: '100%' }} className='btn1'>Submit</button>
        </div>
    )
}
