import React from 'react'

export default function Payment_sucess() {
    const url = window.location.href
    const newurl = new URL(url);
   let data =newurl.get('')

  return (
    <div>Payment_sucess</div>
  )
}
