import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import BottomHero from '../../components/bottom-hero/BottomHero';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from "./shipping_page.module.css";

export default function SimpleAccordion() {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className={style.main_wrapper}>
            <h1>Shipping & Returns</h1>
            <div className={style.shipping_accordian_lists}>
                <div className={style.shipping_accordian_bar}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={style.shipping_Accordion_typography}>
                            Amet, arcu nibh non sed orci.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
                                Aliquam eget maximus est, id dignissim quam.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                              <Typography className={style.shipping_Accordion_typography}>
                            Amet, arcu nibh non sed orci.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
                                varius pulvinar diam eros in elit. Pellentesque convallis laoreet
                                laoreet.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                             <Typography className={style.shipping_Accordion_typography}>
                            Amet, arcu nibh non sed orci.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                                amet egestas eros, vitae egestas augue. Duis vel est augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                              <Typography className={style.shipping_Accordion_typography}>
                            Amet, arcu nibh non sed orci.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
                                amet egestas eros, vitae egestas augue. Duis vel est augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                {/* <div className={style.shipping_accordian_bar}>
                    <Accordion className={style.shipping_Accordion_text}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography}>Amet, arcu nibh non sed orci. </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className={style.shipping_Accordion_text_wrap}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography}>Amet, arcu nibh non sed orci. </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography}>Amet, arcu nibh non sed orci. </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography} >Amet, arcu nibh non sed orci. </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography}>Amet, arcu nibh non sed orci. </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div> */}
                {/* <div className={style.shipping_accordian_bar1}>
                    <Accordion className={style.shipping_Accordion_text1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className={style.shipping_Accordion_text1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={style.shipping_Accordion_typography1}>Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <div className={style.shiipng_border_box}>
                        <div>
                            <h3>Did this answer your question?</h3>
                        </div>
                        <div className={style.tumbs_icons}>
                            <a href=""><i class="fa-regular fa-thumbs-down"></i></a>
                            <i class="fa-solid fa-thumbs-up"></i>
                        </div>
                    </div>
                </div> */}
                <div className={style.shipping_accordian_bar1}>
                    <Accordion className={style.shipping_Accordion_text1} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={style.shipping_Accordion_typography1}>
                            Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6bh-content"
                            id="panel2bh-header"
                        >
                               <Typography className={style.shipping_Accordion_typography1}>
                            Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel7bh-content"
                            id="panel3bh-header"
                        >
                            <Typography className={style.shipping_Accordion_typography1}>
                            Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel8bh-content"
                            id="panel4bh-header"
                        >
                              <Typography className={style.shipping_Accordion_typography1}>
                            Amet, arcu nibh non sed orci. Sapien imperdiet proin tortor laoreet. Ornare egestas urna sed pharetra viverra in sed eu.
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            Odio mi blandit mattis purus nisl vitae platea tortor molestie. At id ipsum massa massa massa lectus euismod convallis. Urna, sit et vivamus sem vulputate orci, non. Gravida tellus justo, justo quis risus. Parturient velit eu ac risus lectus.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <BottomHero />
        </div>
    )
}
