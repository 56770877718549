/**
 * Standard configuration object for Fetch API requests
 */

const fetchReqConfig = {
    method: `POST`,
    redirect: `follow`,
    credentials: `same-origin`,
    headers: {
        'Content-Type': 'application/json',

        /**
         * @definition A request is accepted as a Fetch API request only if it has this flag
         */
        'fetch-req': true
    }
};

export default () => {
    let token = null,
        lang = null;

    if (typeof window !== `undefined`) {
        token = localStorage.getItem(`token`);
        lang = localStorage.getItem(`AnyCarPartLang`);
    }
    
    if (token) fetchReqConfig.headers.Authorization = `Bearer ${token}`;
    if (lang) fetchReqConfig.headers.lang = lang;

    return fetchReqConfig;
};
