
import React from 'react';
import LatestNewsCard from '../../components/latest-news-card/LatestNewsCard';
import Pagination from '../../components/paginations/Pagination';
import style from './newsAndGuide.module.css';
import { useTranslation } from "react-i18next";
import LatestNewsImage1 from '../../assets/images/home/Rectangle 116.png'
import LatestNewsImage2 from '../../assets/images/home/Rectangle 116 (1).png';
import LatestNewsImage3 from '../../assets/images/home/Rectangle 116 (2).png';
import LatestNewsImage4 from '../../assets/images/home/Rectangle 116 (3).png';

export default function NewsAndGuides() {
    const { t } = useTranslation();
    return (
        <main className={style.news_guide_main_wrapper}>
            <h1>{t('latestNewsGuideTitle')} </h1>
            <div className={style.news_guide_section}>
                <LatestNewsCard imageSrc={LatestNewsImage1} />
                <LatestNewsCard imageSrc={LatestNewsImage2} />
                <LatestNewsCard imageSrc={LatestNewsImage3} />
                <LatestNewsCard imageSrc={LatestNewsImage4} />
                <LatestNewsCard imageSrc={LatestNewsImage1} />
                <LatestNewsCard imageSrc={LatestNewsImage2} />
                <LatestNewsCard imageSrc={LatestNewsImage3} />
                <LatestNewsCard imageSrc={LatestNewsImage4} />
                <LatestNewsCard imageSrc={LatestNewsImage1} />
                <LatestNewsCard imageSrc={LatestNewsImage2} />
                <LatestNewsCard imageSrc={LatestNewsImage3} />
                <LatestNewsCard imageSrc={LatestNewsImage4} />

            </div>
            <Pagination classNameForLoadMoreText={style.load_more_text} viewAllBtnText='Load more' svgIcon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_540_2232)">
                    <path d="M9.75048 12.129L13.7735 8.106L14.834 9.1665L9.00048 15L3.16698 9.1665L4.22748 8.106L8.25048 12.129V3H9.75048V12.129Z" fill="#212F63" />
                </g>
                <defs>
                    <clipPath id="clip0_540_2232">
                        <rect width="18" height="18" fill="white" transform="matrix(0 1 -1 0 18 0)" />
                    </clipPath>
                </defs>
            </svg>
            } />
        </main>
    )
}
