import React from 'react';

const DynamicFieldComponents = ({ handleDynamicFields, fields, onSubmit, onancel }) => {

  return (
    <div style={{ backgroundColor: '#F7F7F7', paddingTop:'10px', paddingInline: '20px',paddingBottom:'20px', borderRadius: '5px' }}>
      <form >
        {fields.map((field) => {
          switch (field.type) {
            case 'text':
              return (
                <div key={field._id} style={{ marginBottom: '20px' }}>
                  <label style={{ fontWeight:'600', fontSize: '16px', marginBottom: '10px' }}>{field.label}</label>
                  <input
                    type="text"
                    placeholder={`Enter ${field.label}`}
                    onChange={(e) => handleDynamicFields(field.label, e.target.value)}
                    style={{
                      width: '100%',
                      padding: '12px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      boxSizing: 'border-box',
                      fontSize: '16px',
                      outline: 'none',
                    }}
                    required
                  />
                </div>
              );
            case 'radio':
              return (
                <div key={field._id} style={{ marginBottom: '20px' }}>
                  <label style={{ fontWeight:'600', fontSize: '16px', marginBottom: '10px', display: 'block' }}>{field.label}</label>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {field.options.map((option) => (
                      <label key={option} style={{fontWeight:'400', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        <input
                          type="radio"
                          name={field.label}
                          value={option}
                          onChange={(e) => handleDynamicFields(field.label, e.target.value)}
                          style={{ marginRight: '10px', cursor: 'pointer' }}
                        />
                        <span style={{ marginLeft: '5px' }}>{option}</span>
                      </label>
                    ))}
                  </div>
                </div>
              );
            case 'dropdown':
              return (
                <div key={field._id} style={{ marginBottom: '20px' }}>
                  <label style={{fontWeight:'600', fontSize: '16px', marginBottom: '10px' }}>{field.label}</label>
                  <select
                    onChange={(e) => handleDynamicFields(field.label, e.target.value)}
                    style={{
                      width: '100%',
                      padding: '12px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      boxSizing: 'border-box',
                      fontSize: '16px',
                      outline: 'none',
                    }}
                    required
                  >
                    <option value="">Select {field.label}</option>
                    {field.options.map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              );
            default:
              return null;
          }
        })}
    
      </form>
      {/* <p style={{ marginTop: '20px', textAlign: 'center', color: 'grey' }}>
        For more information please visit <a href="#" style={{ color: 'black' }}>returns policy</a> of Anycarpart.
      </p> */}
    </div>
  );
};

export default DynamicFieldComponents;
