import React from 'react';

const CancelPopup = ({ title, onClose, onContinueShopping }) => {
  return (
    <div style={{ 
      position: 'fixed', 
      top: '40%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      width: '60%', 
        // background: 'rgba(255, 255, 255, 1)', 
      backgroundColor:'#FFF',
      borderRadius: '10px', 
      textAlign: 'center', 
      padding: '50px', 
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(0, 0, 0, 0.2)'
    }}>
      <div style={{ alignItems: 'center' }}>
        <svg width="123" height="123" viewBox="0 0 123 123" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M61.5 0C49.3364 0 37.4459 3.60675 27.3324 10.3647C17.2189 17.1222 9.3363 26.7273 4.68132 37.9649C0.0266806 49.2024 -1.19114 61.5681 1.18154 73.4982C3.55455 85.4279 9.41211 96.3862 18.013 104.987C26.6138 113.588 37.5721 119.445 49.5019 121.818C61.4319 124.191 73.7972 122.973 85.0351 118.319C96.2727 113.664 105.878 105.781 112.635 95.6677C119.393 85.5538 123 73.6637 123 61.5001C122.981 45.1949 116.496 29.563 104.967 18.0334C93.437 6.50405 77.8051 0.0186159 61.5 0ZM93.1287 51.8357L58.1965 86.7503C57.3808 87.5671 56.4117 88.2152 55.3452 88.6573C54.2784 89.0993 53.1354 89.3268 51.9808 89.3268C50.826 89.3268 49.683 89.0993 48.6164 88.6573C47.5496 88.2152 46.5809 87.5671 45.7648 86.7503L29.8713 70.8832C28.2239 69.2311 27.3003 66.9924 27.3037 64.6594C27.307 62.3263 28.2368 60.09 29.8889 58.4427C31.541 56.795 33.7799 55.8717 36.113 55.8747C38.4461 55.8781 40.682 56.8082 42.3297 58.4603L51.994 68.1243L80.6706 39.4213C82.3166 37.7696 84.5518 36.8391 86.8839 36.8347C89.2163 36.8307 91.4546 37.7533 93.1067 39.3997C94.7587 41.0456 95.6892 43.2809 95.6932 45.613C95.6973 47.9454 94.775 50.1837 93.1287 51.8357Z" fill="#0DDF3B" />
        </svg>
      </div>
      <p style={{ fontSize: 40, fontStyle:'normal', fontWeight: '700', margin: '20px 0', color:'black' }}>{title}</p>

      <button
        onClick={onContinueShopping}
        style={{
          backgroundColor: '#212F63',
          fontWeight: '500',
          fontSize: 14,
          width: 200,
          padding: '10px',
          color: '#fff',
          marginRight: '10px',
        }}
      >
        Continue Shopping
      </button>

      <button
        onClick={onClose}
        style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', fontSize: '18px' }}
      >
        X
      </button>
    </div>
  );
};

export default CancelPopup;
