import React from 'react';
import LatestNewsCard from '../../components/latest-news-card/LatestNewsCard';
import HeroImage from '../../components/hero-image/HeroImage';
import ProductHeroImage from '../../assets/images/NewsDetailHero.png';
import style from "./news.module.css";
import { useTranslation } from "react-i18next";

export default function NewsDetails() {
    const { t } = useTranslation();
    return (
        <>
            <HeroImage HeroImage={ProductHeroImage} Heading={t('NewsDetail')} />
            <div className={style.main_wrapper}>

                <div className={style.article_section}>
                    <div className={style.article_socail_icons} >
                        <div>
                            <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                        </div>
                        <div>
                            <a href="#"><i class="fa-brands fa-instagram"></i></a>
                        </div>
                        <div>
                            <a href="#"><i class="fa-brands fa-twitter"></i></a>
                        </div>
                    </div>
                    <div className={style.article_content}>
                        <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus. Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
                    </div>
                </div>
                <section className={style.article_News_section}>
                    <div className={style.article_text}>
                        <h2>
                            Nunc, velit a nunc lacus. Molestie a commodo risus in sed.
                        </h2>
                        <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus. Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
                    </div>
                    <div className={style.article_img_box}>
                        <div>
                            <img src="/assets/Rectangle 116.png" alt="" srcset="" />
                        </div>
                        <div>
                            <img src="/assets/Rectangle 117.png" alt="" srcset="" />
                        </div>
                    </div>
                    <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus. Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
                    <div className={style.article_news_text}>
                        <h3>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. </h3>
                        <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus. Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
                    </div>
                    <div className={style.article_quotes}>
                        <div>
                            <i class="fa-solid fa-quote-right"></i>
                        </div>
                        <div>
                            <h4>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu.
                                <p>Nunc, velit a nunc lacus.</p>
                            </h4>
                        </div>
                    </div>

                </section>
                <div className={style.article_News_socail_icons}>
                        <div>
                            <h4><a href="#"></a>{t('NewsDetailShare')}</h4>
                        </div>
                        <div>
                            <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                        </div>
                        <div>
                            <a href="#"><i class="fa-brands fa-instagram"></i></a>
                        </div>
                        <div>
                            <a href="#"><i class="fa-brands fa-twitter"></i></a>
                        </div>
                    </div>
                <section className={style.related_article_news_section}>
                    <h1>{t('NewsDetailPost')}</h1>
                    
                    <div className={style.related_article_news_card}>
                        <LatestNewsCard />
                        <LatestNewsCard />
                        <LatestNewsCard />
                        <LatestNewsCard />
                    </div>
                </section>

            </div>
        </>
    )
}
