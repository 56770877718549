import React from 'react'
import style from "./pricematch.module.css";
import { useTranslation } from "react-i18next";
export default function Pricematch() {
   const { t } = useTranslation();
   return (
      <div className={style.main_wrapper}>
         <div className={style.privacy_policy_text}>
            <h1>{t('PriceMatch')}</h1>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
         <div className={style.privacy_policy_content}>
            <h3>Nunc sodales semper tincidunt.</h3>
            <p>Nunc, velit a nunc lacus. Molestie a commodo risus in sed. Nulla nec rhoncus arcu egestas. Nec dui scelerisque sed fermentum, elementum vestibulum ultrices pharetra, amet. Praesent neque, in pellentesque arcu. Augue semper aliquet et, a enim quis. Duis purus urna, nec scelerisque ut parturient. Facilisis eu at odio amet hendrerit at mi, euismod. Eleifend et, nullam nibh consectetur fusce risus auctor. Malesuada tortor fermentum, massa, odio erat amet. Morbi vel pretium mauris commodo elementum nunc duis egestas. Eu ante tellus dignissim risus ullamcorper vestibulum imperdiet dis purus.
               Magna tristique elementum elementum pellentesque magna quis risus. Quis sed sodales nam montes sem.</p>
         </div>
      </div>
   )
}
